export const texts = [
    {
      sctitle: "三字经",
      tctitle: "三字經",
      pytitle: "San Zi Jing",
      entitle: "Three Character Classic",
      othertitle: "",
      scauthor: "",
      tcauthor: "王應麟",
      enauthor: "",
      school: "Confucianism",
      dynasty: "",
      year: "",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: ["傳統版"],
      content: [
        {
          chapter: "全文",
          paragraphs: [
            "人之初，性本善，性相近，習相遠。",
            "茍不教，性乃遷，教之道，貴以專。",
            "昔孟母，擇鄰處，子不學，斷機杼。",
            "竇燕山，有義方，教五子，名俱揚。",
            "養不教，父之過，教不嚴，師之惰。",
            "子不學，非所宜，幼不學，老何為？",
            "玉不琢，不成器，人不學，不知義。",
            "為人子，方少時，親師友，習禮儀。",
            "香九齡，能溫席，孝於親，所當執。",
            "融四歲，能讓梨，弟於長，宜先知。",
            "首孝弟，次見聞，知某數，識某文。",
            "一而十，十而百，百而千，千而萬。",
            "三才者，天地人，三光者，日月星。",
            "三綱者，君臣義，父子親，夫婦順。",
            "曰春夏，曰秋冬，此四時，運不窮。",
            "曰南北，曰西東，此四方，應乎中。",
            "曰水火，木金土，此五行，本乎數。",
            "曰仁義，禮智信，此五常，不容紊。",
            "稻粱菽，麥黍稷，此六谷，人所食。",
            "馬牛羊，雞犬豕，此六畜，人所飼。",
            "曰喜怒，曰哀懼，愛惡欲，七情具。",
            "匏土革，木石金，絲與竹，乃八音。",
            "高曾祖，父而身，身而子，子而孫。",
            "自子孫，至玄曾，乃九族，人之倫。",
            "父子恩，夫婦從，兄則友，弟則恭。",
            "長幼序，友與朋，君則敬，臣則忠。",
            "此十義，人所同。",
            "凡訓蒙，須講究，祥訓詁，明句讀。",
            "為學者，必有初，小學終，至四書。",
            "論語者，二十篇，群弟子，記善言。",
            "孟子者，七篇止，講道德，說仁義。",
            "作中庸，子思筆，中不偏，庸不易。",
            "作大學，乃曾子，自脩齊，至平治。",
            "孝經通，四書熟，如六經，始可讀。",
            "詩書易，禮春秋，號六經，當講求。",
            "有連山，有歸藏，有周易，三易祥。",
            "有典謨，有訓誥，有誓命，書之奧。",
            "我周公，作周禮，著六官，存治體。",
            "大小戴，註禮記，述聖言，禮樂備。",
            "曰國風，曰雅頌，號四詩，當諷詠。",
            "詩既亡，春秋作，寓褒貶，別善惡。",
            "三傳者，有公羊，有左氏，有谷梁。",
            "經既明，方讀子，撮其要，記其事。",
            "五子者，有荀揚，文中子，及老莊。",
            "經子通，讀諸史，考世系，知終始。",
            "自羲農，至黃帝，號三皇，居上世。",
            "唐有虞，號二帝，相揖遜，稱盛世。",
            "夏有禹，商有湯，周文武，稱三王。",
            "夏傳子，家天下，四百載，遷夏社。",
            "湯伐夏，國號商，六百載，至紂亡。",
            "周武王，始誅紂，八百載，最長久。",
            "周撤東，王綱墜，逞幹戈，尚遊說。",
            "始春秋，終戰國，五霸強，七雄出。",
            "嬴秦氏，始兼並，傳二世，楚漢爭。",
            "高祖興，漢業建，至孝平，王莽篡。",
            "光武興，為東漢，四百年，終於獻。",
            "魏蜀吳，爭漢鼎，號三國，迄兩晉。",
            "宋齊繼，梁陳承，為南朝，都金陵。",
            "北元魏，分東西，宇文周，與高齊。",
            "迨至隋，一土宇，不再傳，失統緒。",
            "唐高祖，起義師，除隋亂，創國基。",
            "二十傳，三百載，梁滅之，國乃改。",
            "梁唐晉，及漢周，稱五代，皆有由。",
            "炎宋興，受周禪，十八傳，南北混。",
            "遼與金，皆稱帝，元滅金，絕宋世。",
            "蒞中國，兼戎狄，九十年，國祚廢。",
            "太祖興，國大明，號洪武，都金陵。",
            "迨成祖，遷燕京，十七世，至崇禎。",
            "權閹肆，寇如林，至李闖，神器焚。",
            "清太祖，膺景命，靖四方，克大定。",
            "廿一史，全在茲，載治亂，知興衰。",
            "讀史者，考實錄，通古今，若親目。",
            "口而誦，心而惟，朝於斯，夕於斯。",
            "昔仲尼，師項橐，古聖賢，尚勤學。",
            "趙中令，讀魯論，彼既仕，學且勤。",
            "披蒲編，削竹簡，彼無書，且知勉。",
            "頭懸梁，錐刺股，彼不教，自勤苦。",
            "如囊螢，如映雪，家雖貧，學不輟。",
            "如負薪，如掛角，身雖勞，猶苦卓。",
            "蘇老泉，二十七，始發奮，讀書籍。",
            "彼既老，猶悔遲，爾小生，宜早思。",
            "若梁灝，八十二，對大廷，魁多士。",
            "彼既成，眾稱異，爾小生，宜立誌。",
            "瑩八歲，能詠詩，泌七歲，能賦碁。",
            "彼穎悟，人稱奇，爾幼學，當效之。",
            "蔡文姬，能辨琴，謝道韞，能詠吟。",
            "彼女子，且聰敏，爾男子，當自警。",
            "唐劉晏，方七歲，舉神童，作正字。",
            "彼雖幼，身已仕，爾幼學，勉而致。",
            "有為者，亦若是。",
            "犬守夜，雞司晨，茍不學，曷為人？",
            "蠶吐絲，蜂釀蜜，人不學，不如物。",
            "幼而學，壯而行，上致君，下澤民。",
            "揚名聲，顯父母，光於前，裕於後。",
            "人遺子，金滿籯，我教子，惟一經。",
            "勤有功，戲無益，戒之哉，宜勉力。",
          ],
        },
      ],
    },
    {
      sctitle: "千字文",
      tctitle: "千字文",
      entitle: "Thousand Character Classic",
      othertitle: "",
      scauthor: "",
      tcauthor: "周兴嗣",
      enauthor: "",
      school: "Confucianism",
      dynasty: "南北朝",
      year: "",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [
        {
          chapter: "全文",
          paragraphs: [
            "天地玄黃",
            "宇宙洪荒",
            "日月盈昃",
            "辰宿列張",
            "寒來暑往",
            "秋收冬藏",
            "閏餘成歲",
            "律呂調陽",
            "雲騰致雨",
            "露結為霜",
            "金生麗水",
            "玉出崐岡",
            "劍號巨闕",
            "珠稱夜光",
            "果珍李柰",
            "菜重芥姜",
            "海鹹河淡",
            "鱗潛羽翔",
            "龍師火帝",
            "鳥官人皇",
            "始制文字",
            "乃服衣裳",
            "推位讓國",
            "有虞陶唐",
            "吊民伐罪",
            "周發殷湯",
            "坐朝問道",
            "垂拱平章",
            "愛育黎首",
            "臣伏戎羌",
            "遐邇壹體",
            "率賓歸王",
            "鳴鳳在樹",
            "白駒食場",
            "化被草木",
            "賴及萬方",
            "蓋此身髮",
            "四大五常",
            "恭惟鞠養",
            "豈敢毀傷",
            "女慕貞絜",
            "男效才良",
            "知過必改",
            "得能莫忘",
            "罔談彼短",
            "靡恃己長",
            "信使可覆",
            "器欲難量",
            "墨悲絲染",
            "詩贊羔羊",
            "景行維賢",
            "克念作聖",
            "德建名立",
            "形端表正",
            "空谷傳聲",
            "虛堂習聽",
            "禍因惡積",
            "福緣善慶",
            "尺璧非寶",
            "寸陰是競",
            "資父事君",
            "曰嚴與敬",
            "孝當竭力",
            "忠則盡命",
            "臨深履薄",
            "夙興溫凊",
            "似蘭斯馨",
            "如松之盛",
            "川流不息",
            "淵澄取映",
            "容止若思",
            "言辭安定",
            "篤初誠美",
            "慎終宜令",
            "榮業所基",
            "籍甚無竟",
            "學優登仕",
            "攝職從政",
            "存以甘棠",
            "去而益詠",
            "樂殊貴賤",
            "禮別尊卑",
            "上和下睦",
            "夫唱婦隨",
            "外受傅訓",
            "入奉母儀",
            "諸姑伯叔",
            "猶子比兒",
            "孔懷兄弟",
            "同氣連枝",
            "交友投分",
            "切磨箴規",
            "仁慈隱惻",
            "造次弗離",
            "節義廉退",
            "顛沛匪虧",
            "性靜情逸",
            "心動神疲",
            "守真誌滿",
            "逐物意移",
            "堅持雅操",
            "好爵自縻",
            "都邑華夏",
            "東西二京",
            "背邙面洛",
            "浮渭據涇",
            "宮殿盤郁",
            "樓觀飛驚",
            "圖寫禽獸",
            "畫彩仙靈",
            "丙舍旁啟",
            "甲帳對楹",
            "肆筵設席",
            "鼓瑟吹笙",
            "升階納陛",
            "弁轉疑星",
            "右通廣內",
            "左達承明",
            "既集墳典",
            "亦聚群英",
            "杜稿鐘隸",
            "漆書壁經",
            "府羅將相",
            "路俠槐卿",
            "戶封八縣",
            "家給千兵",
            "高冠陪輦",
            "驅轂振纓",
            "世祿侈富",
            "車駕肥輕",
            "策功茂實",
            "勒碑刻銘",
            "磻溪伊尹",
            "佐時阿衡",
            "奄宅曲阜",
            "微旦孰營",
            "桓公匡合",
            "濟弱扶傾",
            "綺回漢惠",
            "說感武丁",
            "俊乂密勿",
            "多士寔寧",
            "晉楚更霸",
            "趙魏困橫",
            "假途滅虢",
            "踐土會盟",
            "何遵約法",
            "韓弊煩刑",
            "起翦頗牧",
            "用軍最精",
            "宣威沙漠",
            "馳譽丹青",
            "九州禹跡",
            "百郡秦並",
            "嶽宗恆岱",
            "禪主云亭",
            "雁門紫塞",
            "雞田赤城",
            "昆池碣石",
            "鉅野洞庭",
            "曠遠綿邈",
            "巖岫杳冥",
            "治本於農",
            "務茲稼穡",
            "俶載南畝",
            "我藝黍稷",
            "稅熟貢新",
            "勸賞黜陟",
            "孟軻敦素",
            "史魚秉直",
            "庶幾中庸",
            "勞謙謹敕",
            "聆音察理",
            "鑒貌辨色",
            "貽厥嘉猷",
            "勉其祗植",
            "省躬譏誡",
            "寵增抗極",
            "殆辱近恥",
            "林臯幸即",
            "兩疏見機",
            "解徂誰逼",
            "索居閑處",
            "沈默寂寥",
            "求古尋論",
            "散慮逍遙",
            "欣奏累遣",
            "慼謝歡招",
            "渠荷的歷",
            "園莽抽條",
            "枇杷晚翠",
            "梧桐早雕",
            "陳根委翳",
            "落葉飄搖",
            "遊鹍獨運",
            "淩摩絳霄",
            "耽讀玩市",
            "寓目囊箱",
            "易輶攸畏",
            "屬耳垣墻",
            "具膳餐飯",
            "適口充腸",
            "飽飫烹宰",
            "饑厭糟糠",
            "親戚故舊",
            "老少異糧",
            "妾禦績紡",
            "侍巾帷房",
            "紈扇圓潔",
            "銀燭煒煌",
            "晝眠夕寐",
            "藍筍象床",
            "弦歌酒宴",
            "接杯舉觴",
            "矯手頓足",
            "悅豫且康",
            "嫡後嗣續",
            "祭祀烝嘗",
            "稽顙再拜",
            "悚懼恐惶",
            "箋牒簡要",
            "顧答審詳",
            "骸垢想浴",
            "執熱願涼",
            "驢騾犢特",
            "駭躍超驤",
            "誅斬賊盜",
            "捕獲叛亡",
            "布射僚丸",
            "嵇琴阮嘯",
            "恬筆倫紙",
            "鈞巧任釣",
            "釋紛利俗",
            "竝皆佳妙",
            "毛施淑姿",
            "工顰妍笑",
            "年矢每催",
            "曦暉朗曜",
            "璇璣懸斡",
            "晦魄環照",
            "指薪修祜",
            "永綏吉劭",
            "矩步引領",
            "俯仰廊廟",
            "束帶矜莊",
            "徘徊瞻眺",
            "孤陋寡聞",
            "愚蒙等誚",
            "謂語助者",
            "焉哉乎也",
          ],
          spells: [
            "tiān dì xuán huáng",
            "yǔ zhòu hóng huāng",
            "rì yuè yíng zè",
            "chén xiù liè zhāng",
            "hán lái shǔ wǎng",
            "qiū shōu dōng cáng",
            "rùn yú chéng suì",
            "lǜ lǚ tiáo yáng",
            "yún téng zhì yǔ",
            "lù jié wéi shuāng",
            "jīn shēng lí shuǐ",
            "yù chū kūn gāng",
            "jiàn hào jù quē",
            "zhū chēng yè guāng",
            "guǒ zhēn lǐ nài",
            "cài zhòng jiè jiāng",
            "hǎi xián hé dàn",
            "lín qián yǔ xiáng",
            "lóng shī huǒ dì",
            "niǎo guān rén huáng",
            "shǐ zhì wén zì",
            "nǎi fú yī shāng",
            "tuī wèi ràng guó",
            "yǒu yú táo táng",
            "diào mín fá zuì",
            "zhōu fā yīn tāng",
            "zuò cháo wèn dào",
            "chuí gǒng píng zhāng",
            "ài yù lí shǒu",
            "chén fú róng qiāng",
            "xiá ěr yī tǐ",
            "shuài bīn guī wáng",
            "míng fèng zài shù",
            "bái jū shí cháng",
            "huà pi cǎo mù",
            "lài jí wàn fāng",
            "gài cǐ shēn fà",
            "sì dà wǔ cháng",
            "gōng wéi jū yǎng",
            "qǐ gǎn huǐ shāng",
            "nǚ mù zhēn jié",
            "nán xiào cái liáng",
            "zhī guò bì gǎi",
            "dé néng mò wàng",
            "wǎng tán bǐ duǎn",
            "mǐ shì jǐ cháng",
            "xìn shǐ kě fù",
            "qì yù nán liáng",
            "mò bēi sī rǎn",
            "shī zàn gāo yáng",
            "jǐng xíng wéi xián",
            "kè niàn zuò shèng",
            "dé jiàn míng lì",
            "xíng duān biǎo zhèng",
            "kōng gǔ chuán shēng",
            "xū táng xí tīng",
            "huò yīn è jī",
            "fú yuán shàn qìng",
            "chǐ bì fēi bǎo",
            "cùn yīn shì jìng",
            "zī fù shì jūn",
            "yuē yán yǔ jìng",
            "xiào dāng jié lì",
            "zhōng zé jìn mìng",
            "lín shēn lǚ bó",
            "sù xīng wēn qìng",
            "sì lán sī xīn",
            "rú sōng zhī shèng",
            "chuān liú bù xī",
            "yuān chéng qǔ yìng",
            "róng zhǐ ruò sī",
            "yán cí ān dìng",
            "dǔ chū chéng měi",
            "shèn zhōng yí lìng",
            "róng yè suǒ jī",
            "jí shèn wú jìng",
            "xué yōu dēng shì",
            "shè zhí cóng zhèng",
            "cún yǐ gān táng",
            "qù ér yì yǒng",
            "yuè shū guì jiàn",
            "lǐ bié zūn bēi",
            "shàng hé xià mù",
            "fū chàng fù suí",
            "wài shòu fù xùn",
            "rù fèng mǔ yí",
            "zhū gū bó shū",
            "yóu zǐ bǐ ér",
            "kǒng huái xiōng dì",
            "tóng qì lián zhī",
            "jiāo yǒu tóu fèn",
            "qiē mó zhēn guī",
            "rén cí yǐn cè",
            "zào cì fú lí",
            "jié yì lián tuì",
            "diān pèi fēi kuī",
            "xìng jìng qíng yì",
            "xīn dòng shén pí",
            "shǒu zhēn zhì mǎn",
            "zhú wù yì yí",
            "jiān chí yǎ cāo",
            "hǎo jué zì mí",
            "dū yì huá xià",
            "dōng xī èr jīng",
            "bèi máng miàn luò",
            "fú wèi jù jīng",
            "gōng diàn pán yù",
            "lóu guàn fēi jīng",
            "tú xiě qín shòu",
            "huà cǎi xiān líng",
            "bǐng shè páng qǐ",
            "jiǎ zhàng duì yíng",
            "sì yán shè xí",
            "gǔ sè chuī shēng",
            "shēng jiē nà bì",
            "biàn zhuàn yí xīng",
            "yòu tōng guǎng nèi",
            "zuǒ dá chéng míng",
            "jì jí fén diǎn",
            "yì jù qún yīng",
            "dù gǎo zhōng lì",
            "qī shū bì jīng",
            "fǔ luó jiàng xiàng",
            "lù xiá huái qīng",
            "hù fēng bā xiàn",
            "jiā jǐ qiān bīng",
            "gāo guān péi niǎn",
            "qū gǔ zhèn yīng",
            "shì lù chǐ fù",
            "chē jià féi qīng",
            "cè gōng mào shí",
            "lè bēi kè míng",
            "pán xī yī yǐn",
            "zuǒ shí ā héng",
            "yǎn zhái qū fù",
            "wēi dàn shú yíng",
            "huán gōng kuāng hé",
            "jì ruò fú qīng",
            "qǐ huí hàn huì",
            "yuè gǎn wǔ dīng",
            "jùn yì mì wù",
            "duō shì shí níng",
            "jìn chǔ gēng bà",
            "zhào wèi kùn héng",
            "jiǎ tú miè guó",
            "jiàn tǔ huì méng",
            "hé zūn yuē fǎ",
            "hán bì fán xíng",
            "qǐ jiǎn pō mù",
            "yòng jūn zuì jīng",
            "xuān wēi shā mò",
            "chí yù dān qīng",
            "jiǔ zhōu yǔ jì",
            "bǎi jùn qín bìng",
            "yuè zōng héng dài",
            "shàn zhǔ yún tíng",
            "yàn mén zǐ sài",
            "jī tián chì chéng",
            "kūn chí jié shí",
            "jù yě dòng tíng",
            "kuàng yuǎn mián miǎo",
            "yán xiù yǎo míng",
            "zhì běn yú nóng",
            "wù zī jià sè",
            "chù zǎi nán mǔ",
            "wǒ yì shǔ jì",
            "shuì shú gòng xīn",
            "quàn shǎng chù zhì",
            "mèng kē dūn sù",
            "shǐ yú bǐng zhí",
            "shù jī zhōng yōng",
            "láo qiān jǐn chì",
            "líng yīn chá lǐ",
            "jiàn mào biàn sè",
            "yí jué jiā yóu",
            "miǎn qí zhī zhí",
            "xǐng gōng jī jiè",
            "chǒng zēng kàng jí",
            "dài rǔ jìn chǐ",
            "lín gāo xìng jí",
            "liǎng shū jiàn jī",
            "jiě cú shuí bī",
            "suǒ jū xián chù",
            "chén mò jì liáo",
            "qiú gǔ xún lùn",
            "sàn lǜ xiāo yáo",
            "xīn zòu lèi qiǎn",
            "qī xiè huān zhāo",
            "qú hé de lì",
            "yuán mǎng chōu tiáo",
            "pí pá wǎn cuì",
            "wú tóng zǎo diāo",
            "chén gēn wěi yì",
            "luò yè piāo yáo",
            "yóu kūn dú yùn",
            "líng mó jiàng xiāo",
            "dān dú wán shì",
            "yù mù náng xiāng",
            "yì yóu yōu wèi",
            "zhǔ ěr yuán qiáng",
            "jù shàn cān fàn",
            "shì kǒu chōng cháng",
            "bǎo yù pēng zǎi",
            "jī yàn zāo kāng",
            "qīn qī gù jiù",
            "lǎo shào yì liáng",
            "qiè yù jì fǎng",
            "shì jīn wéi fáng",
            "wán shàn yuán jié",
            "yín zhú wěi huáng",
            "zhòu mián xī mèi",
            "lán sǔn xiàng chuáng",
            "xián gē jiǔ yàn",
            "jiē bēi jǔ shāng",
            "jiǎo shǒu dùn zú",
            "yuè yù qiě kāng",
            "dí hòu sì xù",
            "jì sì zhēng cháng",
            "qǐ sǎng zài bài",
            "sǒng jù kǒng huáng",
            "jiān dié jiǎn yào",
            "gù dá shěn xiáng",
            "hái gòu xiǎng yù",
            "zhí rè yuàn liáng",
            "lǘ luó dú tè",
            "hài yuè chāo xiāng",
            "zhū zhǎn zéi dào",
            "bǔ huò pàn wáng",
            "bù shè liáo wán",
            "jī qín ruǎn xiào",
            "tián bǐ lún zhǐ",
            "jūn qiǎo rén diào",
            "shì fēn lì sú",
            "bìng jiē jiā miào",
            "máo shī shū zī",
            "gōng pín yán xiào",
            "nián shǐ měi cuī",
            "xī huī lǎng yào",
            "xuán jī xuán wò",
            "huì pò huán zhào",
            "zhǐ xīn xiū hù",
            "yǒng suí jí shào",
            "jǔ bù yǐn lǐng",
            "fǔ yǎng láng miào",
            "shù dài jīn zhuāng",
            "pái huái zhān tiào",
            "gū lòu guǎ wén",
            "yú méng děng qiào",
            "wèi yǔ zhù zhě",
            "yān zāi hū yě",
          ],
        },
      ],
    },
  
    {
      sctitle: "论语",
      tctitle: "論語",
      pytitle: "Lun Yu",
      entitle: "The Analects",
      othertitle: "The Analects of Confucius, The Confucian Analects",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Confucius",
      school: "Confucianism",
      dynasty: "Spring and Autumn, Warring States",
      year: "480 BC-350 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [
        {
          chapter: "学而篇",
          paragraphs: [
            "子曰：“学而时习之，不亦说乎？有朋自远方来，不亦乐乎？人不知而不愠，不亦君子乎？”",
            "有子曰：“其为人也孝弟，而好犯上者，鲜矣；不好犯上而好作乱者，未之有也。君子务本，本立而道生。孝弟也者，其为仁之本与！”",
            "子曰：“巧言令色，鲜矣仁！”",
            "曾子曰：“吾日三省吾身：为人谋而不忠乎？与朋友交而不信乎？传不习乎？”",
            "子曰：“道千乘之国，敬事而信，节用而爱人，使民以时。”",
            "子曰：“弟子入则孝，出则弟，谨而信，泛爱众，而亲仁，行有余力，则以学文。”",
            "子夏曰：“贤贤易色；事父母，能竭其力；事君，能致其身；与朋友交，言而有信。虽曰未学，吾必谓之学矣。”",
            "子曰：“君子不重则不威，学则不固。主忠信，无友不如己者，过，则勿惮改。”",
            "曾子曰：“慎终追远，民德归厚矣。”",
            "子禽问于子贡曰：“夫子至于是邦也，必闻其政，求之与，抑与之与？”子贡曰：“夫子温、良、恭、俭、让以得之。夫子之求之也，其诸异乎人之求之与？”",
            "子曰：“父在，观其志；父没，观其行；三年无改于父之道，可谓孝矣。”",
            "有子曰：“礼之用，和为贵。先王之道，斯为美，小大由之。有所不行，知和而和，不以礼节之，亦不可行也。”",
            "有子曰：“信近于义，言可复也。恭近于礼，远耻辱也。因不失其亲，亦可宗也。”",
            "子曰：“君子食无求饱，居无求安，敏于事而慎于言，就有道而正焉。可谓好学也已。”",
            "子贡曰：“贫而无谄，富而无骄，何如？”子曰：“可也。未若贫而乐，富而好礼者也。”子贡曰：“《诗》云：‘如切如磋，如琢如磨’，其斯之谓与？”子曰：“赐也，始可与言《诗》已矣，告诸往而知来者。”",
            "子曰：“不患人之不己知，患不知人也。”",
          ],
        },
        {
          chapter: "为政篇",
          paragraphs: [
            "子曰：“为政以德，譬如北辰，居其所而众星共之。”",
            "子曰：“《诗》三百，一言以蔽之，曰：‘思无邪’。”",
            "子曰：“道之以政，齐之以刑，民免而无耻。道之以德，齐之以礼，有耻且格。”",
            "子曰：“吾十有五而志于学，三十而立，四十而不惑，五十而知天命，六十而耳顺，七十而从心所欲，不逾矩。”",
            "孟懿子问孝，子曰：“无违。”樊迟御，子告之曰：“孟孙问孝于我，我对曰‘无违’。”樊迟曰：“何谓也？”子曰：“生，事之以礼；死，葬之以礼，祭之以礼。”",
            "孟武伯问孝。子曰：“父母唯其疾之忧。”",
            "子游问孝。子曰：“今之孝者，是谓能养。至于犬马皆能有养；不敬，何以别乎？”",
            "子夏问孝。子曰：“色难。有事，弟子服其劳；有酒食，先生馔，曾是以为孝乎？”",
            "子曰：“吾与回言终日，不违，如愚。退而省其私，亦足以发，回也不愚。”",
            "子曰：“视其所以，观其所由，察其所安，人焉廋哉？人焉廋哉？”",
            "子曰：“温故而知新，可以为师矣。”",
            "子曰：“君子不器。”",
            "子贡问君子。子曰：“先行其言而后从之。”",
            "子曰：“君子周而不比，小人比而不周。”",
            "子曰：“学而不思则罔，思而不学则殆。”",
            "子曰：“攻乎异端，斯害也已！”",
            "子曰：“由，诲汝知之乎！知之为知之，不知为不知，是知也。”",
            "子张学干禄。子曰：“多闻阙疑，慎言其余，则寡尤；多见阙殆，慎行其余，则寡悔。言寡尤，行寡悔，禄在其中矣。”",
            "哀公问曰：“何为则民服？”孔子对曰：“举直错诸枉，则民服；举枉错诸直，则民不服。”",
            "季康子问：“使民敬、忠以劝，如之何？”子曰：“临之以庄，则敬；孝慈，则忠；举善而教不能，则劝。”",
            "或谓孔子曰：“子奚不为政？”子曰：“《书》云：‘孝乎惟孝，友于兄弟，施于有政。’是亦为政，奚其为为政？”",
            "子曰：“人而无信，不知其可也。大车无輗，小车无軏，其何以行之哉？”",
            "子张问：“十世可知也？”子曰：“殷因于夏礼，所损益，可知也；周因于殷礼，所损益，可知也。其或继周者，虽百世，可知也。”",
            "子曰：“非其鬼而祭之，谄也；见义不为，无勇也。”",
          ],
        },
        {
          chapter: "八佾篇",
          paragraphs: [
            "孔子谓季氏:“八佾舞于庭，是可忍也，孰不可忍也?”",
            "三家者以《雍》彻,子曰：“‘相维辟公，天子穆穆’，奚取于三家之堂？”",
            "子曰：“人而不仁，如礼何？人而不仁，如乐何？”",
            "林放问礼之本,子曰：“大哉问！礼，与其奢也，宁俭；丧，与其易也，宁戚。”",
            "子曰：“夷狄之有君，不如诸夏之亡也。”",
            "季氏旅于泰山。子谓冉有曰：“女弗能救与？”对曰：“不能。”子曰：“呜呼！曾谓泰山不如林放乎？”",
            "子曰：“君子无所争，必也射乎！揖让而升，下而饮。其争也君子。”",
            "子夏问曰：“‘巧笑倩兮，美目盼兮，素以为绚兮’何谓也？”子曰：“绘事后素。”曰：“礼后乎？”子曰：“起予者商也，始可与言《诗》已矣。”",
            "子曰：“夏礼吾能言之，杞不足征也；殷礼吾能言之，宋不足征也。文献不足故也，足则吾能征之矣。”",
            "子曰：“禘自既灌而往者，吾不欲观之矣。”",
            "或问禘之说。子曰：“不知也。知其说者之于天下也，其如示诸斯乎！”指其掌。",
            "祭如在，祭神如神在。子曰：“吾不与祭，如不祭。”",
            "王孙贾问曰：“‘与其媚于奥，宁媚于灶’，何谓也？”子曰：“不然，获罪于天，无所祷也。”",
            "子曰：“周监于二代，郁郁乎文哉！吾从周。”",
            "子入太庙，每事问。或曰：“孰谓鄹人之子知礼乎？入太庙，每事问。”子闻之，曰：“是礼也。”",
            "子曰：“射不主皮，为力不同科，古之道也。”",
            "子贡欲去告朔之饩羊，子曰：“赐也！尔爱其羊，我爱其礼。”",
            "子曰：“事君尽礼，人以为谄也。”",
            "定公问：“君使臣，臣事君，如之何？”孔子对曰：“君使臣以礼，臣事君以忠。”",
            "子曰：“《关睢》，乐而不淫，哀而不伤。”",
            "哀公问社于宰我，宰我对曰：“夏后氏以松，殷人以柏，周人以栗，曰使民战栗。”子闻之，曰：“成事不说，遂事不谏，既往不咎。”",
            "子曰：“管仲之器小哉！”或曰：“管仲俭乎？”曰：“管氏有三归，官事不摄，焉得俭？”“然则管仲知礼乎？”曰：“邦君树塞门，管氏亦树塞门；邦君为两君之好，有反坫。管氏亦有反坫，管氏而知礼，孰不知礼？”",
            "子语鲁大师乐，曰：“乐其可知也。始作，翕如也；从之，纯如也，皦如也，绎如也，以成。”",
            "仪封人请见，曰：“君子之至于斯也，吾未尝不得见也。”从者见之。出曰：“二三子何患于丧乎？天下之无道也久矣，天将以夫子为木铎。”",
            "子谓《韶》：“尽美矣，又尽善也。”谓《武》：“尽美矣，未尽善也。”",
            "子曰：“居上不宽，为礼不敬，临丧不哀，吾何以观之哉？”",
          ],
        },
        {
          chapter: "里仁篇",
          paragraphs: [
            "子曰：“里仁为美。择不处仁，焉得知？”",
            "子曰：“不仁者不可以久处约，不可以长处乐。仁者安仁，知者利仁。”",
            "子曰：“唯仁者能好人，能恶人。”",
            "子曰：“苟志於仁矣，无恶也。”",
            "子曰：“富与贵，是人之所欲也；不以其道得之，不处也。贫与贱，是人之所恶也；不以其道得之，不去也。君子去仁，恶乎成名？君子无终食之间违仁，造次必于是，颠沛必于是。”",
            "子曰：“我未见好仁者，恶不仁者。好仁者，无以尚之；恶不仁者，其为仁矣，不使不仁者加乎其身。有能一日用其力于仁矣乎？我未见力不足者。盖有之矣，我未见也。”",
            "子曰：“人之过也，各於其党。观过，斯知仁矣。”",
            "子曰：“朝闻道，夕死可矣。”",
            "子曰：“士志于道，而耻恶衣恶食者，未足与议也。”",
            "子曰：“君子之于天下也，无适也，无莫也，义之与比。”",
            "子曰：“君子怀德，小人怀土；君子怀刑，小人怀惠。”",
            "子曰：“放于利而行，多怨。”",
            "子曰：“能以礼让为国乎？何有？不能以礼让为国，如礼何？”",
            "子曰：“不患无位，患所以立。不患莫己知，求为可知也。”",
            "子曰：“参乎！吾道一以贯之。”曾子曰：“唯。”子出，门人问曰：“何谓也？”曾子曰：“夫子之道，忠恕而已矣。”",
            "子曰：“君子喻于义，小人喻于利。”",
            "子曰：“见贤思齐焉，见不贤而内自省也。”",
            "子曰：“事父母，几谏，谏志不从，又敬不违，劳而不怨。”",
            "子曰：“父母在，不远游，游必有方。”",
            "子曰：“三年无改于父之道，可谓孝矣。”",
            "子曰：“父母之年，不可不知也，一则以喜，一则以惧。”",
            "子曰：“古者言之不出，耻躬之不逮也。”",
            "子曰：“以约失之者鲜矣。”",
            "子曰：“君子欲讷于言而敏于行。”",
            "子曰：“德不孤，必有邻。”",
            "子游曰：“事君数，斯辱矣；朋友数，斯疏矣。”",
          ],
        },
        {
          chapter: "公冶长篇",
          paragraphs: [
            "子谓公冶长：“可妻也，虽在缧绁之中，非其罪也！”以其子妻之。",
            "子谓南容：“邦有道不废；邦无道免于刑戮。”以其兄之子妻之。",
            "子谓子贱：“君子哉若人！鲁无君子者，斯焉取斯？”",
            "子贡问曰：“赐也何如？”子曰：“女器也。”曰：“何器也？”曰：“瑚琏也。”",
            "或曰：“雍也仁而不佞。”子曰：“焉用佞？御人以口给，屡憎于人。不知其仁，焉用佞？”",
            "子使漆雕开仕，对曰：“吾斯之未能信。”子说。",
            "子曰：“道不行，乘桴浮于海，从我者其由与？”子路闻之喜，子曰：“由也好勇过我，无所取材。”",
            "孟武伯问：“子路仁乎？”子曰：“不知也。”又问，子曰：“由也，千乘之国，可使治其赋也，不知其仁也。”“求也何如？”子曰：“求也，千室之邑、百乘之家，可使为之宰也，不知其仁也。”“赤也何如？”子曰：“赤也，束带立于朝，可使与宾客言也，不知其仁也。”",
            "子谓子贡曰：“女与回也孰愈？”对曰：“赐也何敢望回？回也闻一以知十，赐也闻一以知二。”子曰：“弗如也，吾与女弗如也！”",
            "宰予昼寝，子曰：“朽木不可雕也，粪土之墙不可杇也，于予与何诛？”子曰：“始吾于人也，听其言而信其行；今吾于人也，听其言而观其行。于予与改是。”",
            "子曰：“吾未见刚者。”或对曰：“申枨。”子曰：“枨也欲，焉得刚。”",
            "子贡曰：“我不欲人之加诸我也，吾亦欲无加诸人。”子曰：“赐也，非尔所及也。”",
            "子贡曰：“夫子之文章，可得而闻也；夫子之言性与天道，不可得而闻也。”",
            "子路有闻，未之能行，唯恐有闻。",
            "子贡问曰：“孔文子何以谓之文也？”子曰：“敏而好学，不耻下问，是以谓之文也。”",
            "子谓子产：“有君子之道四焉：其行己也恭，其事上也敬，其养民也惠，其使民也义。”",
            "子曰：“晏平仲善与人交，久而敬之。”",
            "子曰：“臧文仲居蔡，山节藻棁，何如其知也？”",
            "子张问曰：“令尹子文三仕为令尹，无喜色，三已之无愠色，旧令尹之政必以告新令尹，何如？”子曰：“忠矣。”曰：“仁矣乎？”曰：“未知，焉得仁？”“崔子弑齐君，陈文子有马十乘，弃而违之。至于他邦，则曰：‘犹吾大夫崔子也。’违之。之一邦，则又曰：‘犹吾大夫崔子也。’违之，何如？”子曰：“清矣。”曰：“仁矣乎？”曰：“未知，焉得仁？”",
            "季文子三思而后行，子闻之曰：“再斯可矣。”",
            "子曰：“宁武子，邦有道则知，邦无道则愚。其知可及也，其愚不可及也。”",
            "子在陈，曰：“归与！归与！吾党之小子狂简，斐然成章，不知所以裁之。”",
            "子曰：“伯夷、叔齐不念旧恶，怨是用希。”",
            "子曰：“孰谓微生高直？或乞醯焉，乞诸其邻而与之。”",
            "子曰：“巧言、令色、足恭，左丘明耻之，丘亦耻之。匿怨而友其人，左丘明耻之，丘亦耻之。”",
            "颜渊、季路侍，子曰：“盍各言尔志？”子路曰：“愿车马、衣轻裘与朋友共，敝之而无憾。”颜渊曰：“愿无伐善，无施劳。”子路曰：“愿闻子之志。”子曰：“老者安之，朋友信之，少者怀之。”",
            "子曰：“已矣乎！吾未见能见其过而内自讼者也。”",
            "子曰：“十室之邑，必有忠信如丘者焉，不如丘之好学也。”",
          ],
        },
        {
          chapter: "雍也篇",
          paragraphs: [
            "子曰：“雍也可使南面。”",
            "仲弓问子桑伯子，子曰：“可也简。”仲弓曰：“居敬而行简，以临其民，不亦可乎？居简而行简，无乃大简乎？”子曰：“雍之言然。”",
            "哀公问：“弟子孰为好学？”孔子对曰：“有颜回者好学，不迁怒，不贰过，不幸短命死矣，今也则亡，未闻好学者也。”",
            "子华使于齐，冉子为其母请粟，子曰：“与之釜。”请益，曰：“与之庾。”冉子与之粟五秉。子曰：“赤之适齐也，乘肥马，衣轻裘。吾闻之也，君子周急不继富。”",
            "原思为之宰，与之粟九百，辞。子曰：“毋，以与尔邻里乡党乎！”",
            "子谓仲弓曰：“犁牛之子骍且角，虽欲勿用，山川其舍诸？”",
            "子曰：“回也，其心三月不违仁，其余则日月至焉而已矣。”",
            "季康子问：“仲由可使从政也与？”子曰：“由也果，于从政乎何有？”曰：“赐也可使从政也与？”曰：“赐也达，于从政乎何有？”曰：“求也可使从政也与？”曰：“求也艺，于从政乎何有？”",
            "季氏使闵子骞为费宰，闵子骞曰：“善为我辞焉。如有复我者，则吾必在汶上矣。”",
            "伯牛有疾，子问之，自牖执其手，曰：“亡之，命矣夫！斯人也而有斯疾也！斯人也而有斯疾也！”",
            "子曰：“贤哉回也！一箪食，一瓢饮，在陋巷，人不堪其忧，回也不改其乐。贤哉，回也！”",
            "冉求曰：“非不说子之道，力不足也。”子曰：“力不足者，中道而废，今女画。”",
            "子谓子夏曰：“女为君子儒，无为小人儒。”",
            "子游为武城宰，子曰：“女得人焉尔乎？”曰：“有澹台灭明者，行不由径，非公事，未尝至于偃之室也。”",
            "子曰：“孟之反不伐，奔而殿，将入门，策其马曰：‘非敢后也，马不进也。’”",
            "子曰：“不有祝鮀之佞，而有宋朝之美，难乎免于今之世矣。”",
            "子曰：“谁能出不由户？何莫由斯道也？”",
            "子曰：“质胜文则野，文胜质则史。文质彬彬，然后君子。”",
            "子曰：“人之生也直，罔之生也幸而免。”",
            "子曰：“知之者不如好之者；好之者不如乐之者。”",
            "子曰：“中人以上，可以语上也；中人以下，不可以语上也。”",
            "樊迟问知，子曰：“务民之义，敬鬼神而远之，可谓知矣。”问仁，曰：“仁者先难而后获，可谓仁矣。”",
            "子曰：“知者乐水，仁者乐山。知者动，仁者静。知者乐，仁者寿。”",
            "子曰：“齐一变至于鲁，鲁一变至于道。”",
            "子曰：“觚不觚，觚哉！觚哉！”",
            "宰我问曰：“仁者，虽告之曰：‘井有仁焉。’其从之也？”子曰：“何为其然也？君子可逝也，不可陷也；可欺也，不可罔也。”",
            "子曰：“君子博学于文，约之以礼，亦可以弗畔矣夫。”",
            "子见南子，子路不说，夫子矢之曰：“予所否者，天厌之！天厌之！”",
            "子曰：“中庸之为德也，其至矣乎！民鲜久矣。”",
            "子贡曰：“如有博施于民而能济众，何如？可谓仁乎？”子曰：“何事于仁，必也圣乎！尧、舜其犹病诸！夫仁者，己欲立而立人，己欲达而达人。能近取譬，可谓仁之方也已。”",
          ],
        },
        {
          chapter: "述而篇",
          paragraphs: [
            "子曰：“述而不作，信而好古，窃比于我老彭。”",
            "子曰：“默而识之，学而不厌，诲人不倦，何有于我哉？”",
            "子曰：“德之不修，学之不讲，闻义不能徙，不善不能改，是吾忧也。”",
            "子之燕居，申申如也，夭夭如也。",
            "子曰：“甚矣吾衰也！久矣吾不复梦见周公。”",
            "子曰：“志于道，据于德，依于仁，游于艺。”",
            "子曰：“自行束修以上，吾未尝无诲焉。”",
            "子曰：“不愤不启，不悱不发，举一隅不以三隅反，则不复也。”",
            "子食于有丧者之侧，未尝饱也。",
            "子于是日哭，则不歌。",
            "子谓颜渊曰：“用之则行，舍之则藏，惟我与尔有是夫！”子路曰：“子行三军，则谁与？”子曰：“暴虎冯河，死而无悔者，吾不与也。必也临事而惧，好谋而成者也。”",
            "子曰：“富而可求也，虽执鞭之士，吾亦为之。如不可求，从吾所好。”",
            "子之所慎：齐，战，疾。",
            "子在齐闻《韶》，三月不知肉味，曰：“不图为乐之至于斯也。”",
            "冉有曰：“夫子为卫君乎？”子贡曰：“诺，吾将问之。”入，曰：“伯夷、叔齐何人也？”曰：“古之贤人也。”曰：“怨乎？”曰：“求仁而得仁，又何怨？”出，曰：“夫子不为也。”",
            "子曰：“饭疏食饮水，曲肱而枕之，乐亦在其中矣。不义而富且贵，于我如浮云。”",
            "子曰：“加我数年，五十以学《易》，可以无大过矣。”",
            "子所雅言，《诗》、《书》、执礼，皆雅言也。",
            "叶公问孔子于子路，子路不对。子曰：“女奚不曰：其为人也，发愤忘食，乐以忘忧，不知老之将至云尔。”",
            "子曰：“我非生而知之者，好古，敏以求之者也。”",
            "子不语怪、力、乱、神。",
            "子曰：“三人行，必有我师焉。择其善者而从之，其不善者而改之。”",
            "子曰：“天生德于予，桓魋其如予何？”",
            "子曰：“二三子以我为隐乎？吾无隐乎尔！吾无行而不与二三子者，是丘也。”",
            "子以四教：文，行，忠，信。",
            "子曰：“圣人，吾不得而见之矣；得见君子者斯可矣。”子曰：“善人，吾不得而见之矣，得见有恒者斯可矣。亡而为有，虚而为盈，约而为泰，难乎有恒乎。”",
            "子钓而不纲，弋不射宿。",
            "子曰：“盖有不知而作之者，我无是也。多闻，择其善者而从之；多见而识之，知之次也。”",
            "互乡难与言，童子见，门人惑。子曰：“与其进也，不与其退也，唯何甚？人洁己以进，与其洁也，不保其往也。”",
            "子曰：“仁远乎哉？我欲仁，斯仁至矣。”",
            "陈司败问：“昭公知礼乎？”孔子曰：“知礼。”孔子退，揖巫马期而进之，曰：“吾闻君子不党，君子亦党乎？君取于吴，为同姓，谓之吴孟子。君而知礼，孰不知礼？”巫马期以告，子曰：“丘也幸，苟有过，人必知之。”",
            "子与人歌而善，必使反之，而后和之。",
            "子曰：“文，莫吾犹人也。躬行君子，则吾未之有得。”",
            "子曰：“若圣与仁，则吾岂敢？抑为之不厌，诲人不倦，则可谓云尔已矣。”公西华曰：“正唯弟子不能学也。”",
            "子疾病，子路请祷。子曰：“有诸？”子路对曰：“有之。《诔》曰：‘祷尔于上下神祇。’”子曰：“丘之祷久矣。”",
            "子曰：“奢则不孙，俭则固。与其不孙也，宁固。”",
            "子曰：“君子坦荡荡，小人长戚戚。”",
            "子温而厉，威而不猛，恭而安。",
          ],
        },
        {
          chapter: "泰伯篇",
          paragraphs: [
            "子曰：“泰伯，其可谓至德也已矣。三以天下让，民无得而称焉。”",
            "子曰：“恭而无礼则劳；慎而无礼则葸；勇而无礼则乱；直而无礼则绞。君子笃于亲，则民兴于仁；故旧不遗，则民不偷。”",
            "曾子有疾，召门弟子曰：“启予足，启予手。《诗》云：‘战战兢兢，如临深渊，如履薄冰。’而今而后，吾知免夫，小子！”",
            "曾子有疾，孟敬子问之。曾子言曰：“鸟之将死，其鸣也哀；人之将死，其言也善。君子所贵乎道者三：动容貌，斯远暴慢矣；正颜色，斯近信矣；出辞气，斯远鄙倍矣。笾豆之事，则有司存。”",
            "曾子曰：“以能问于不能；以多问于寡；有若无，实若虚，犯而不校。昔者吾友尝从事于斯矣。”",
            "曾子曰：“可以托六尺之孤，可以寄百里之命，临大节而不可夺也。君子人与？君子人也。”",
            "曾子曰：“士不可以不弘毅，任重而道远。仁以为己任，不亦重乎？死而后已，不亦远乎？”",
            "子曰：“兴于《诗》，立于礼，成于乐。”",
            "子曰：“民可使由之，不可使知之。”",
            "子曰：“好勇疾贫，乱也。人而不仁，疾之已甚，乱也。”",
            "子曰：“如有周公之才之美，使骄且吝，其余不足观也已。”",
            "子曰：“三年学，不至于谷，不易得也。”",
            "子曰：“笃信好学，守死善道。危邦不入，乱邦不居。天下有道则见，无道则隐。邦有道，贫且贱焉，耻也；邦无道，富且贵焉，耻也。”",
            "子曰：“不在其位，不谋其政。”",
            "子曰：“师挚之始，《关雎》之乱，洋洋乎盈耳哉！”",
            "子曰：“狂而不直，侗而不愿，悾悾而不信，吾不知之矣。”",
            "子曰：“学如不及，犹恐失之。”",
            "子曰：“巍巍乎！舜、禹之有天下也而不与焉。”",
            "子曰：“大哉尧之为君也！巍巍乎，唯天为大，唯尧则之。荡荡乎，民无能名焉。巍巍乎其有成功也，焕乎其有文章！”",
            "舜有臣五人而天下治。武王曰：“予有乱臣十人。”孔子曰：“才难，不其然乎？唐虞之际，于斯为盛；有妇人焉，九人而已。三分天下有其二，以服事殷。周之德，其可谓至德也已矣。”",
            "子曰：“禹，吾无间然矣。菲饮食而致孝乎鬼神，恶衣服而致美乎黼冕，卑宫室而尽力乎沟洫。禹，吾无间然矣。”",
          ],
        },
        {
          chapter: "子罕篇",
          paragraphs: [
            "子罕言利与命与仁。",
            "达巷党人曰：“大哉孔子！博学而无所成名。”子闻之，谓门弟子曰：“吾何执？执御乎，执射乎？吾执御矣。”",
            "子曰：“麻冕，礼也；今也纯，俭，吾从众。拜下，礼也；今拜乎上，泰也；虽违众，吾从下。”",
            "子绝四：毋意、毋必、毋固、毋我。",
            "子畏于匡，曰：“文王既没，文不在兹乎？天之将丧斯文也，后死者不得与于斯文也；天之未丧斯文也，匡人其如予何？”",
            "太宰问于子贡曰：“夫子圣者与，何其多能也？”子贡曰：“固天纵之将圣，又多能也。”子闻之，曰：“太宰知我乎？吾少也贱，故多能鄙事。君子多乎哉？不多也。”",
            "牢曰：“子云：‘吾不试，故艺。’”",
            "子曰：“吾有知乎哉？无知也。有鄙夫问于我，空空如也。我叩其两端而竭焉。”",
            "子曰：“凤鸟不至，河不出图，吾已矣夫！”",
            "子见齐衰者、冕衣裳者与瞽者，见之，虽少，必作，过之必趋。",
            "颜渊喟然叹曰：“仰之弥高，钻之弥坚。瞻之在前，忽焉在后。夫子循循然善诱人，博我以文，约我以礼，欲罢不能。既竭吾才，如有所立卓尔，虽欲从之，末由也已。”",
            "子疾病，子路使门人为臣。病间，曰：“久矣哉，由之行诈也！无臣而为有臣，吾谁欺？欺天乎？且予与其死于臣之手也，无宁死于二三子之手乎！且予纵不得大葬，予死于道路乎？”",
            "子贡曰：“有美玉于斯，韫椟而藏诸？求善贾而沽诸？”子曰：“沽之哉，沽之哉！我待贾者也。”",
            "子欲居九夷。或曰：“陋，如之何？”子曰：“君子居之，何陋之有！”",
            "子曰：“吾自卫反鲁，然后乐正，《雅》、《颂》各得其所。”",
            "子曰：“出则事公卿，入则事父兄，丧事不敢不勉，不为酒困，何有于我哉？”",
            "子在川上曰：“逝者如斯夫！不舍昼夜。”",
            "子曰：“吾未见好德如好色者也。”",
            "子曰：“譬如为山，未成一篑，止，吾止也；譬如平地，虽覆一篑，进，吾往也。”",
            "子曰：“语之而不惰者，其回也与！”",
            "子谓颜渊，曰：“惜乎！吾见其进也，未见其止也。”",
            "子曰：“苗而不秀者有矣夫，秀而不实者有矣夫。”",
            "子曰：“后生可畏，焉知来者之不如今也？四十、五十而无闻焉，斯亦不足畏也已。”",
            "子曰：“法语之言，能无从乎？改之为贵。巽与之言，能无说乎？绎之为贵。说而不绎，从而不改，吾末如之何也已矣。”",
            "子曰：“主忠信。毋友不如己者，过，则勿惮改。”",
            "子曰：“三军可夺帅也，匹夫不可夺志也。”",
            "子曰：“衣敝缊袍，与衣狐貉者立而不耻者，其由也与！‘不忮不求，何用不臧？’”子路终身诵之，子曰：“是道也，何足以臧？”",
            "子曰：“岁寒，然后知松柏之后凋也。”",
            "子曰：“知者不惑，仁者不忧，勇者不惧。”",
            "子曰：“可与共学，未可与适道；可与适道，未可与立；可与立，未可与权。”",
            "“唐棣之华，偏其反而。岂不尔思？室是远尔。”子曰：“未之思也，夫何远之有。”",
          ],
        },
        {
          chapter: "乡党篇",
          paragraphs: [
            "孔子于乡党，恂恂如也，似不能言者；其在宗庙朝庭，便便言，唯谨尔。",
            "朝，与下大夫言，侃侃如也；与上大夫言，訚訚如也。君在，踧踖如也，与与如也。",
            "君召使摈，色勃如也，足躩如也。揖所与立，左右手，衣前后襜如也。趋进，翼如也。宾退，必复命曰：“宾不顾矣。”",
            "入公门，鞠躬如也，如不容。立不中门，行不履阈。过位，色勃如也，足躩如也，其言似不足者。摄齐升堂，鞠躬如也，屏气似不息者。出，降一等，逞颜色，怡怡如也；没阶，趋进，翼如也；复其位，踧踖如也。",
            "执圭，鞠躬如也，如不胜。上如揖，下如授。勃如战色，足蹜蹜如有循。享礼，有容色。私觌，愉愉如也。",
            "君子不以绀緅饰，红紫不以为亵服。当暑袗絺绤，必表而出之。缁衣羔裘，素衣麑裘，黄衣狐裘。亵裘长，短右袂。必有寝衣，长一身有半。狐貉之厚以居。去丧，无所不佩。非帷裳，必杀之。羔裘玄冠不以吊。吉月，必朝服而朝。",
            "齐，必有明衣，布。齐必变食，居必迁坐。",
            "食不厌精，脍不厌细。食饐而餲，鱼馁而肉败，不食；色恶，不食；臭恶，不食；失饪，不食；不时，不食；割不正，不食；不得其酱，不食。肉虽多，不使胜食气。唯酒无量，不及乱。沽酒市脯，不食。不撤姜食，不多食。",
            "祭于公，不宿肉。祭肉不出三日，出三日不食之矣。",
            "食不语，寝不言。",
            "虽疏食菜羹，必祭，必齐如也。",
            "席不正，不坐。",
            "乡人饮酒，杖者出，斯出矣。",
            "乡人傩，朝服而立于阼阶。",
            "问人于他邦，再拜而送之。",
            "康子馈药，拜而受之。曰：“丘未达，不敢尝。”",
            "厩焚，子退朝，曰：“伤人乎？”不问马。",
            "君赐食，必正席先尝之；君赐腥，必熟而荐之；君赐生，必畜之。侍食于君，君祭，先饭。",
            "疾，君视之，东首，加朝服，拖绅。",
            "君命召，不俟驾行矣。",
            "入太庙，每事问。",
            "朋友死，无所归，曰：“于我殡。”",
            "朋友之馈，虽车马，非祭肉，不拜。",
            "寝不尸，居不客。",
            "见齐衰者，虽狎，必变。见冕者与瞽者，虽亵，必以貌。凶服者式之，式负版者。有盛馔，必变色而作。迅雷风烈，必变。",
            "升车，必正立，执绥。车中不内顾，不疾言，不亲指。",
            "色斯举矣，翔而后集。曰：“山梁雌雉，时哉时哉！”子路共之，三嗅而作。",
          ],
        },
        {
          chapter: "先进篇",
          paragraphs: [
            "子曰：“先进于礼乐，野人也；后进于礼乐，君子也。如用之，则吾从先进。”",
            "子曰：“从我于陈、蔡者，皆不及门也。”",
            "德行：颜渊，闵子骞，冉伯牛，仲弓。言语：宰我，子贡。政事：冉有，季路。文学：子游，子夏。",
            "子曰：“回也非助我者也，于吾言无所不说。”",
            "子曰：“孝哉闵子骞！人不间于其父母昆弟之言。”",
            "南容三复白圭，孔子以其兄之子妻之。",
            "季康子问：“弟子孰为好学？”孔子对曰：“有颜回者好学，不幸短命死矣，今也则亡。”",
            "颜渊死，颜路请子之车以为之椁。子曰：“才不才，亦各言其子也。鲤也死，有棺而无椁，吾不徒行以为之椁。以吾从大夫之后，不可徒行也。”",
            "颜渊死。子曰：“噫！天丧予！天丧予！”",
            "颜渊死，子哭之恸，从者曰：“子恸矣！”曰：“有恸乎？非夫人之为恸而谁为？”",
            "颜渊死，门人欲厚葬之，子曰：“不可。”门人厚葬之，子曰：“回也视予犹父也，予不得视犹子也。非我也，夫二三子也！”",
            "季路问事鬼神，子曰：“未能事人，焉能事鬼？”,曰：“敢问死。”曰：“未知生，焉知死？”",
            "闵子侍侧，訚訚如也；子路，行行如也；冉有、子贡，侃侃如也。子乐。“若由也，不得其死然。”",
            "鲁人为长府，闵子骞曰：“仍旧贯如之何？何必改作？”子曰：“夫人不言，言必有中。”",
            "子曰：“由之瑟奚为于丘之门？”门人不敬子路，子曰：“由也升堂矣，未入于室也。”",
            "子贡问：“师与商也孰贤？”子曰：“师也过，商也不及。”曰：“然则师愈与？”子曰：“过犹不及。”",
            "季氏富于周公，而求也为之聚敛而附益之。子曰：“非吾徒也，小子鸣鼓而攻之可也。”",
            "柴也愚，参也鲁，师也辟，由也喭。",
            "子曰：“回也其庶乎，屡空。赐不受命而货殖焉，亿则屡中。”",
            "子张问善人之道，子曰：“不践迹，亦不入于室。”",
            "子曰：“论笃是与，君子者乎，色庄者乎？”",
            "子路问：“闻斯行诸？”子曰：“有父兄在，如之何其闻斯行之？”冉有问：“闻斯行诸？”子曰：“闻斯行之。公西华曰：“由也问闻斯行诸，子曰‘有父兄在’；求也问闻斯行诸，子曰‘闻斯行之’。赤也惑，敢问。”子曰：“求也退，故进之；由也兼人，故退之。”",
            "子畏于匡，颜渊后。子曰：“吾以女为死矣！”曰：“子在，回何敢死！”",
            "季子然问：“仲由、冉求可谓大臣与？”子曰：“吾以子为异之问，曾由与求之问。所谓大臣者，以道事君，不可则止。今由与求也，可谓具臣矣。”曰：“然则从之者与？”子曰：“弑父与君，亦不从也。”",
            "子路使子羔为费宰，子曰：“贼夫人之子。”子路曰：“有民人焉，有社稷焉，何必读书然后为学。”子曰：“是故恶夫佞者。”",
            "子路、曾皙、冉有、公西华侍坐，子曰：“以吾一日长乎尔，毋吾以也。居则曰‘不吾知也’如或知尔，则何以哉？”子路率尔而对曰：“千乘之国，摄乎大国之间，加之以师旅，因之以饥馑，由也为之，比及三年，可使有勇，且知方也。”夫子哂之。“求，尔何如？”对曰：“方六七十，如五六十，求也为之，比及三年，可使足民。如其礼乐，以俟君子。”“赤！尔何如？”对曰：“非曰能之，愿学焉。宗庙之事，如会同，端章甫，愿为小相焉。”“点，尔何如？”鼓瑟希，铿尔，舍瑟而作，对曰：“异乎三子者之撰。”子曰：“何伤乎？亦各言其志也。”曰：“暮春者，春服既成，冠者五六人，童子六七人，浴乎沂，风乎舞雩，咏而归。”夫子喟然叹曰：“吾与点也！”三子者出，曾皙后。曾皙曰：“夫三子者之言何如？”子曰：“亦各言其志也已矣。”曰：“夫子何哂由也？”曰：“为国以礼，其言不让，是故哂之。”“唯求则非邦也与？”“安见方六七十、如五六十而非邦也者？”“唯赤则非邦也与？”“宗庙会同，非诸侯而何？赤也为之小，孰能为之大？”",
          ],
        },
        {
          chapter: "颜渊篇",
          paragraphs: [
            "颜渊问仁，子曰：“克己复礼为仁。一日克己复礼，天下归仁焉。为仁由己，而由人乎哉？”颜渊曰：“请问其目？”子曰：“非礼勿视，非礼勿听，非礼勿言，非礼勿动。”颜渊曰：“回虽不敏，请事斯语矣。”",
            "仲弓问仁，子曰：“出门如见大宾，使民如承大祭。己所不欲，勿施于人。在邦无怨，在家无怨。”仲弓曰：“雍虽不敏，请事斯语矣。”",
            "司马牛问仁，子曰：“仁者，其言也讱。”曰：“其言也讱，斯谓之仁已乎？”子曰：“为之难，言之得无讱乎？”",
            "司马牛问君子，子曰：“君子不忧不惧。”曰：“不忧不惧，斯谓之君子已乎？”子曰：“内省不疚，夫何忧何惧？”",
            "司马牛忧曰：“人皆有兄弟，我独亡。”子夏曰：“商闻之矣：死生有命，富贵在天。君子敬而无失，与人恭而有礼，四海之内皆兄弟也。君子何患乎无兄弟也？”",
            "子张问明，子曰：“浸润之谮，肤受之愬，不行焉，可谓明也已矣；浸润之谮、肤受之愬不行焉，可谓远也已矣。”",
            "子贡问政，子曰：“足食，足兵，民信之矣。”子贡曰：“必不得已而去，于斯三者何先？”曰：“去兵。”子贡曰：“必不得已而去，于斯二者何先？”曰：“去食。自古皆有死，民无信不立。”",
            "棘子成曰：“君子质而已矣，何以文为？”子贡曰：“惜乎，夫子之说君子也！驷不及舌。文犹质也，质犹文也。虎豹之鞟犹犬羊之鞟。”",
            "哀公问于有若曰：“年饥，用不足，如之何？”有若对曰：“盍彻乎？”曰：“二，吾犹不足，如之何其彻也？”对曰：“百姓足，君孰与不足？百姓不足，君孰与足？”",
            "子张问崇德辨惑，子曰：“主忠信，徙义，崇德也。爱之欲其生，恶之欲其死；既欲其生又欲其死，是惑也。‘诚不以富，亦只以异。’”",
            "齐景公问政于孔子，孔子对曰：“君君，臣臣，父父，子子。”公曰：“善哉！信如君不君、臣不臣、父不父、子不子，虽有粟，吾得而食诸？”",
            "子曰：“片言可以折狱者，其由也与？”子路无宿诺。",
            "子曰：“听讼，吾犹人也。必也使无讼乎。”",
            "子张问政，子曰：“居之无倦，行之以忠。”",
            "子曰：“博学于文，约之以礼，亦可以弗畔矣夫。”",
            "子曰：“君子成人之美，不成人之恶；小人反是。”",
            "季康子问政于孔子，孔子对曰：“政者，正也。子帅以正，孰敢不正？”",
            "季康子患盗，问于孔子。孔子对曰：“苟子之不欲，虽赏之不窃。”",
            "季康子问政于孔子曰：“如杀无道以就有道，何如？”孔子对曰：“子为政，焉用杀？子欲善而民善矣。君子之德风，小人之德草，草上之风必偃。”",
            "子张问：“士何如斯可谓之达矣？”子曰：“何哉尔所谓达者？”子张对曰：“在邦必闻，在家必闻。”子曰：“是闻也，非达也。夫达也者，质直而好义，察言而观色，虑以下人。在邦必达，在家必达。夫闻也者，色取仁而行违，居之不疑。在邦必闻，在家必闻。”",
            "樊迟从游于舞雩之下，曰：“敢问崇德、修慝、辨惑。”子曰：“善哉问！先事后得，非崇德与？攻其恶，无攻人之恶，非修慝与？一朝之忿，忘其身，以及其亲，非惑与？”",
            "樊迟问仁，子曰：“爱人。”问知，子曰：“知人。”樊迟未达，子曰：“举直错诸枉，能使枉者直。”樊迟退，见子夏，曰：“乡也吾见于夫子而问知，子曰：‘举直错诸枉，能使枉者直’，何谓也？”子夏曰：“富哉言乎！舜有天下，选于众，举皋陶，不仁者远矣。汤有天下，选于众，举伊尹，不仁者远矣。”",
            "子贡问友，子曰：“忠告而善道之，不可则止，毋自辱焉。”",
            "曾子曰：“君子以文会友，以友辅仁。”",
          ],
        },
        {
          chapter: "子路篇",
          paragraphs: [
            "子路问政，子曰：“先之，劳之。”请益，曰：“无倦。”",
            "仲弓为季氏宰，问政，子曰：“先有司，赦小过，举贤才。”曰：“焉知贤才而举之？”曰：“举尔所知。尔所不知，人其舍诸？”",
            "子路曰：“卫君待子而为政，子将奚先？”子曰：“必也正名乎！”子路曰：“有是哉，子之迂也！奚其正？”子曰：“野哉由也！君子于其所不知，盖阙如也。名不正，则言不顺；言不顺，则事不成；事不成，则礼乐不兴；礼乐不兴，则刑罚不中；刑罚不中，则民无所错手足。故君子名之必可言也，言之必可行也。君子于其言，无所苟而已矣。”",
            "樊迟请学稼，子曰：“吾不如老农。”请学为圃，曰：“吾不如老圃。”樊迟出，子曰：“小人哉樊须也！上好礼，则民莫敢不敬；上好义，则民莫敢不服；上好信，则民莫敢不用情。夫如是，则四方之民襁负其子而至矣，焉用稼？”",
            "子曰：“诵《诗》三百，授之以政，不达；使于四方，不能专对；虽多，亦奚以为？”",
            "子曰：“其身正，不令而行；其身不正，虽令不从。”",
            "子曰：“鲁卫之政，兄弟也。”",
            "子谓卫公子荆，“善居室。始有，曰：‘苟合矣。’少有，曰：‘苟完矣。’富有，曰：‘苟美矣。’”",
            "子适卫，冉有仆，子曰：“庶矣哉！”冉有曰：“既庶矣，又何加焉？”曰：“富之。”曰：“既富矣，又何加焉？”曰：“教之。”",
            "子曰：“苟有用我者，期月而已可也，三年有成。”",
            "子曰：“‘善人为邦百年，亦可以胜残去杀矣。’诚哉是言也！”",
            "子曰：“如有王者，必世而后仁。”",
            "子曰：“苟正其身矣，于从政乎何有？不能正其身，如正人何？”",
            "冉子退朝，子曰：“何晏也？”对曰：“有政。”子曰：“其事也。如有政，虽不吾以，吾其与闻之。”",
            "定公问：“一言而可以兴邦，有诸？”孔子对曰：“言不可以若是其几也。人之言曰：‘为君难，为臣不易。’如知为君之难也，不几乎一言而兴邦乎？”曰：“一言而丧邦，有诸？”孔子对曰：“言不可以若是其几也。人之言曰：‘予无乐乎为君，唯其言而莫予违也。’如其善而莫之违也，不亦善乎？如不善而莫之违也，不几乎一言而丧邦乎？”",
            "叶公问政，子曰：“近者说，远者来。”",
            "子夏为莒父宰，问政，子曰：“无欲速，无见小利。欲速则不达，见小利则大事不成。”",
            "叶公语孔子曰：“吾党有直躬者，其父攘羊，而子证之。”孔子曰：“吾党之直者异于是。父为子隐，子为父隐，直在其中矣。”",
            "樊迟问仁，子曰：“居处恭，执事敬，与人忠。虽之夷狄，不可弃也。”",
            "子贡问曰：“何如斯可谓之士矣？”子曰：“行己有耻，使于四方不辱君命，可谓士矣。”曰：“敢问其次。”曰：“宗族称孝焉，乡党称弟焉。”曰：“敢问其次。”曰：“言必信，行必果，踁踁然小人哉！抑亦可以为次矣。”曰：“今之从政者何如？”子曰：“噫！斗筲之人，何足算也！”",
            "子曰：“不得中行而与之，必也狂狷乎！狂者进取，狷者有所不为也。”",
            "子曰：“南人有言曰：‘人而无恒，不可以作巫医。’善夫！”“不恒其德，或承之羞。”子曰：“不占而已矣。”",
            "子曰：“君子和而不同，小人同而不和。”",
            "子贡问曰：“乡人皆好之，何如？”子曰：“未可也。”“乡人皆恶之，何如？”子曰：“未可也。不如乡人之善者好之，其不善者恶之。”",
            "子曰：“君子易事而难说也，说之不以道不说也，及其使人也器之；小人难事而易说也，说之虽不以道说也，及其使人也求备焉。”",
            "子曰：“君子泰而不骄，小人骄而不泰。”",
            "子曰：“刚、毅、木、讷近仁。”",
            "子路问曰：“何如斯可谓之士矣？”子曰：“切切偲偲，怡怡如也，可谓士矣。朋友切切偲偲，兄弟怡怡。”",
            "子曰：“善人教民七年，亦可以即戎矣。”",
            "子曰：“以不教民战，是谓弃之。”",
          ],
        },
        {
          chapter: "宪问篇",
          paragraphs: [
            "宪问耻，子曰：“邦有道，谷；邦无道，谷，耻也。”“克、伐、怨、欲不行焉，可以为仁矣？”子曰：“可以为难矣，仁则吾不知也。”",
            "子曰：“士而怀居，不足以为士矣。”",
            "子曰：“邦有道，危言危行；邦无道，危行言孙。”",
            "子曰：“有德者必有言，有言者不必有德。仁者必有勇，勇者不必有仁。”",
            "南宫适问于孔子曰：“羿善射，奡荡舟，俱不得其死然；禹、稷躬稼而有天下。”夫子不答。南宫适出，子曰：“君子哉若人！尚德哉若人！”",
            "子曰：“君子而不仁者有矣夫，未有小人而仁者也。”",
            "子曰：“爱之，能勿劳乎？忠焉，能勿诲乎？”",
            "子曰：“为命，裨谌草创之，世叔讨论之，行人子羽修饰之，东里子产润色之。”",
            "或问子产，子曰：“惠人也。”问子西，曰：“彼哉，彼哉！”问管仲，曰：“人也。夺伯氏骈邑三百，饭疏食，没齿无怨言。”",
            "子曰：“贫而无怨难，富而无骄易。”",
            "子曰：“孟公绰为赵、魏老则优，不可以为滕、薛大夫。”",
            "子路问成人，子曰：“若臧武仲之知、公绰之不欲、卞庄子之勇、冉求之艺，文之以礼乐，亦可以为成人矣。”曰：“今之成人者何必然？见利思义，见危授命，久要不忘平生之言，亦可以为成人矣。”",
            "子问公叔文子于公明贾曰：“信乎，夫子不言，不笑，不取乎？”公明贾对曰：“以告者过也。夫子时然后言，人不厌其言；乐然后笑，人不厌其笑；义然后取，人不厌其取。”子曰：“其然？岂其然乎？”",
            "子曰：“臧武仲以防求为后于鲁，虽曰不要君，吾不信也。”",
            "子曰：“晋文公谲而不正，齐桓公正而不谲。”",
            "子路曰：“桓公杀公子纠，召忽死之，管仲不死，曰未仁乎？”子曰：“桓公九合诸侯不以兵车，管仲之力也。如其仁，如其仁！”",
            "子贡曰：“管仲非仁者与？桓公杀公子纠，不能死，又相之。”子曰：“管仲相桓公霸诸侯，一匡天下，民到于今受其赐。微管仲，吾其被发左衽矣。岂若匹夫匹妇之为谅也，自经于沟渎而莫之知也。”",
            "公叔文子之臣大夫僎与文子同升诸公，子闻之,曰：“可以为‘文’矣。”",
            "子言卫灵公之无道也，康子曰：“夫如是，奚而不丧？”孔子曰：“仲叔圉治宾客，祝鮀治宗庙，王孙贾治军旅，夫如是，奚其丧？”",
            "子曰：“其言之不怍，则为之也难。”",
            "陈成子弑简公，孔子沐浴而朝，告于哀公曰：“陈恒弑其君，请讨之。”公曰：“告夫三子。”,孔子曰：“以吾从大夫之后，不敢不告也，君曰‘告夫三子’者！”之三子告，不可。孔子曰：“以吾从大夫之后，不敢不告也。”",
            "子路问事君，子曰：“勿欺也，而犯之。”",
            "子曰：“君子上达，小人下达。”",
            "子曰：“古之学者为己，今之学者为人。”",
            "蘧伯玉使人于孔子，孔子与之坐而问焉，曰：“夫子何为？”对曰：“夫子欲寡其过而未能也。”使者出，子曰：“使乎！使乎！”",
            "子曰：“不在其位，不谋其政。”曾子曰：“君子思不出其位。”",
            "子曰：“君子耻其言而过其行。”",
            "子曰：“君子道者三，我无能焉：仁者不忧，知者不惑，勇者不惧。”子贡曰：“夫子自道也。”",
            "子贡方人，子曰：“赐也贤乎哉？夫我则不暇。”",
            "子曰：“不患人之不己知，患其不能也。”",
            "子曰：“不逆诈，不亿不信，抑亦先觉者，是贤乎！”",
            "微生亩谓孔子曰：“丘何为是栖栖者与？无乃为佞乎？”孔子曰：“非敢为佞也，疾固也。”",
            "曰：“骥不称其力，称其德也。”",
            "或曰：“以德报怨，何如？”子曰：“何以报德？以直报怨，以德报德。”",
            "子曰：“莫我知也夫！”子贡曰：“何为其莫知子也？”子曰：“不怨天，不尤人，下学而上达。知我者其天乎！”",
            "公伯寮愬子路于季孙。子服景伯以告，曰：“夫子固有惑志于公伯寮，吾力犹能肆诸市朝。”子曰：“道之将行也与，命也；道之将废也与，命也。公伯寮其如命何？”",
            "子曰：“贤者辟世，其次辟地，其次辟色，其次辟言。”子曰：“作者七人矣。”",
            "子路宿于石门，晨门曰：“奚自？”子路曰：“自孔氏。”曰：“是知其不可而为之者与？”",
            "子击磬于卫，有荷蒉而过孔氏之门者，曰：“有心哉，击磬乎！”既而曰：“鄙哉，硁硁乎！莫己知也，斯己而已矣。深则厉，浅则揭。”子曰：“果哉！末之难矣。”",
            "子张曰：“《书》云，‘高宗谅阴，三年不言。’何谓也？”子曰：“何必高宗，古之人皆然。君薨，百官总己以听于冢宰三年。”",
            "子曰：“上好礼，则民易使也。”",
            "子路问君子，子曰：“修己以敬。”曰：“如斯而已乎？”曰：“修己以安人。”曰：“如斯而已乎？”曰：“修己以安百姓。修己以安百姓，尧、舜其犹病诸！”",
            "原壤夷俟，子曰：“幼而不孙弟，长而无述焉，老而不死，是为贼！”以杖叩其胫。",
            "阙党童子将命，或问之曰：“益者与？”子曰：“吾见其居于位也，见其与先生并行也。非求益者也，欲速成者也。”",
          ],
        },
        {
          chapter: "卫灵公篇",
          paragraphs: [
            "卫灵公问陈于孔子，孔子对曰：“俎豆之事，则尝闻之矣；军旅之事，未之学也。”明日遂行。",
            "在陈绝粮，从者病莫能兴。子路愠见曰：“君子亦有穷乎？”子曰：“君子固穷，小人穷斯滥矣。”",
            "子曰：“赐也，女以予为多学而识之者与？”对曰：“然，非与？”曰：“非也，予一以贯之。”",
            "子曰：“由，知德者鲜矣。”",
            "子曰：“无为而治者其舜也与！夫何为哉？恭己正南面而已矣。”",
            "子张问行，子曰：“言忠信，行笃敬，虽蛮貊之邦行矣；言不忠信，行不笃敬，虽州里行乎哉？立则见其参于前也；在舆则见其倚于衡也，夫然后行。”子张书诸绅。",
            "子曰：“直哉史鱼！邦有道如矢，邦无道如矢。君子哉蘧伯玉！邦有道则仕，邦无道则可卷而怀之。”",
            "子曰：“可与言而不与之言，失人；不可与言而与之言，失言。知者不失人亦不失言。”",
            "子曰：“志士仁人无求生以害仁，有杀身以成仁。”",
            "子贡问为仁，子曰：“工欲善其事，必先利其器。居是邦也，事其大夫之贤者，友其士之仁者。”",
            "颜渊问为邦，子曰：“行夏之时，乘殷之辂，服周之冕，乐则《韶》、《舞》；放郑声，远佞人。郑声淫，佞人殆。”",
            "子曰：“人无远虑，必有近忧。”",
            "子曰：“已矣乎！吾未见好德如好色者也。”",
            "子曰：“臧文仲其窃位者与！知柳下惠之贤而不与立也。”",
            "子曰：“躬自厚而薄责于人，则远怨矣。”",
            "子曰：“不曰‘如之何、如之何’者，吾末如之何也已矣。”",
            "子曰：“群居终日，言不及义，好行小慧，难矣哉！”",
            "子曰：“君子义以为质，礼以行之，孙以出之，信以成之。君子哉！”",
            "子曰：“君子病无能焉，不病人之不己知也。”",
            "子曰：“君子疾没世而名不称焉。”",
            "子曰：“君子求诸己，小人求诸人。”",
            "子曰：“君子矜而不争，群而不党。”",
            "子曰：“君子不以言举人，不以人废言。”",
            "子贡问曰：“有一言而可以终身行之者乎？”子曰：“其恕乎！己所不欲，勿施于人。”",
            "子曰：“吾之于人也，谁毁谁誉？如有所誉者，其有所试矣。斯民也，三代之所以直道而行也。”",
            "子曰：“吾犹及史之阙文也，有马者借人乘之，今亡矣夫！”",
            "子曰：“巧言乱德，小不忍，则乱大谋。”",
            "子曰：“众恶之，必察焉；众好之，必察焉。”",
            "子曰：“人能弘道，非道弘人。”",
            "子曰：“过而不改，是谓过矣。”",
            "子曰：“吾尝终日不食、终夜不寝以思，无益，不如学也。”",
            "子曰：“君子谋道不谋食。耕也馁在其中矣，学也禄在其中矣。君子忧道不忧贫。”",
            "子曰：“知及之，仁不能守之，虽得之，必失之；知及之，仁能守之，不庄以莅之，则民不敬；知及之，仁能守之，庄以莅之，动之不以礼，未善也。”",
            "子曰：“君子不可小知而可大受也，小人不可大受而可小知也。”",
            "子曰：“民之于仁也，甚于水火。水火，吾见蹈而死者矣，未见蹈仁而死者也。”",
            "子曰：“当仁不让于师。”",
            "子曰：“君子贞而不谅。”",
            "子曰：“事君，敬其事而后其食。”",
            "子曰：“有教无类。”",
            "子曰：“道不同，不相为谋。”",
            "子曰：“辞达而已矣。”",
            "师冕见，及阶，子曰：“阶也。”及席，子曰：“席也。”皆坐，子告之曰：“某在斯，某在斯。”师冕出。子张问曰：“与师言之道与？”子曰：“然，固相师之道也。”",
          ],
        },
        {
          chapter: "季氏篇",
          paragraphs: [
            "季氏将伐颛臾，冉有、季路见于孔子，曰：“季氏将有事于颛臾。”孔子曰：“求，无乃尔是过与？夫颛臾，昔者先王以为东蒙主，且在邦域之中矣，是社稷之臣也。何以伐为？”冉有曰：“夫子欲之，吾二臣者皆不欲也。”孔子曰：“求，周任有言曰：‘陈力就列，不能者止。’危而不持，颠而不扶，则将焉用彼相矣？且尔言过矣，虎兕出于柙，龟玉毁于椟中，是谁之过与？”冉有曰：“今夫颛臾固而近于费，今不取，后世必为子孙忧。”孔子曰：“求，君子疾夫舍曰欲之而必为之辞。丘也闻，有国有家者，不患寡而患不均，不患贫而患不安。盖均无贫，和无寡，安无倾。夫如是，故远人不服则修文德以来之，既来之，则安之。今由与求也相夫子，远人不服而不能来也，邦分崩离析而不能守也，而谋动干戈于邦内。吾恐季孙之忧不在颛臾，而在萧墙之内也。”",
            "孔子曰：“天下有道，则礼乐征伐自天子出；天下无道，则礼乐征伐自诸侯出。自诸侯出，盖十世希不失矣；自大夫出，五世希不失矣；陪臣执国命，三世希不失矣。天下有道，则政不在大夫；天下有道，则庶人不议。”",
            "孔子曰：“禄之去公室五世矣，政逮于大夫四世矣，故夫三桓之子孙微矣。”",
            "孔子曰：“益者三友，损者三友。友直、友谅、友多闻，益矣；友便辟、友善柔、有便佞，损矣。”",
            "孔子曰：“益者三乐，损者三乐。乐节礼乐、乐道人之善、乐多贤友，益矣；乐骄乐、乐佚游、乐宴乐，损矣。”",
            "孔子曰：“侍于君子有三愆：言未及之而言谓之躁，言及之而不言谓之隐，未见颜色而言谓之瞽。”",
            "孔子曰：“君子有三戒：少之时，血气未定，戒之在色；及其壮也，血气方刚，戒之在斗；及其老也，血气既衰，戒之在得。”",
            "孔子曰：“君子有三畏：畏天命，畏大人，畏圣人之言。小人不知天命而不畏也，狎大人，侮圣人之言。”",
            "孔子曰：“生而知之者上也，学而知之者次也；困而学之又其次也。困而不学，民斯为下矣。”",
            "孔子曰：“君子有九思：视思明，听思聪，色思温，貌思恭，言思忠，事思敬，疑思问，忿思难，见得思义。”",
            "孔子曰：“见善如不及，见不善如探汤；吾见其人矣。吾闻其语矣。隐居以求其志，行义以达其道；吾闻其语矣，未见其人也。”",
            "齐景公有马千驷，死之日，民无德而称焉；伯夷、叔齐饿于首阳之下，民到于今称之。其斯之谓与？”",
            "陈亢问于伯鱼曰：“子亦有异闻乎？”对曰：“未也。尝独立，鲤趋而过庭，曰：‘学《诗》乎？’对曰：‘未也。’‘不学《诗》，无以言。’鲤退而学《诗》。他日，又独立，鲤趋而过庭，曰：‘学《礼》乎？’对曰：‘未也。’‘不学《礼》，无以立。’鲤退而学《礼》。闻斯二者。”陈亢退而喜曰：“问一得三，闻《诗》，闻《礼》，又闻君子之远其子也。”",
            "邦君之妻，君称之曰夫人，夫人自称曰小童；邦人称之曰君夫人，称诸异邦曰寡小君；异邦人称之亦曰君夫人。",
          ],
        },
        {
          chapter: "阳货篇",
          paragraphs: [
            "阳货欲见孔子，孔子不见，归孔子豚。孔子时其亡也而往拜之，遇诸涂。谓孔子曰：“来，予与尔言。”曰：“怀其宝而迷其邦，可谓仁乎？”曰：“不可。”“好从事而亟失时，可谓知乎？”曰：“不可！”“日月逝矣，岁不我与！”孔子曰：“诺，吾将仕矣。”",
            "子曰：“性相近也，习相远也。”",
            "子曰：“唯上知与下愚不移。”",
            "子之武城，闻弦歌之声。夫子莞尔而笑，曰：“割鸡焉用牛刀？”子游对曰：“昔者偃也闻诸夫子曰：‘君子学道则爱人，小人学道则易使也。’”子曰：“二三子，偃之言是也！前言戏之耳。”",
            "公山弗扰以费畔，召，子欲往。子路不说，曰：“末之也已，何必公山氏之之也？”子曰：“夫召我者而岂徒哉？如有用我者，吾其为东周乎！”",
            "子张问仁于孔子，孔子曰：“能行五者于天下为仁矣。”请问之，曰：“恭、宽、信、敏、惠。恭则不侮，宽则得众，信则人任焉，敏则有功，惠则足以使人。”",
            "佛肸召，子欲往。子路曰：“昔者由也闻诸夫子曰：‘亲于其身为不善者，君子不入也。’佛肸以中牟畔，子之往也，如之何？”子曰：“然，有是言也。不曰坚乎，磨而不磷；不曰白乎，涅而不缁。吾岂匏瓜也哉？焉能系而不食？”",
            "子曰：“由也，女闻六言六蔽矣乎？”对曰：“未也。”“居！吾语女。好仁不好学，其蔽也愚；好知不好学，其蔽也荡；好信不好学，其蔽也贼；好直不好学，其蔽也绞；好勇不好学，其蔽也乱；好刚不好学，其蔽也狂。”",
            "子曰：“小子何莫学夫《诗》？《诗》可以兴，可以观，可以群，可以怨。迩之事父，远之事君，多识于鸟兽草木之名。”",
            "子谓伯鱼曰：“女为《周南》、《召南》矣乎？人而不为《周南》、《召南》，其犹正墙面而立也与！”",
            "子曰：“礼云礼云，玉帛云乎哉？乐云乐云，钟鼓云乎哉？”",
            "子曰：“色厉而内荏，譬诸小人，其犹穿窬之盗也与？”",
            "子曰：“乡愿，德之贼也。”",
            "子曰：“道听而涂说，德之弃也。”",
            "子曰：“鄙夫可与事君也与哉？其未得之也，患得之；既得之，患失之。苟患失之，无所不至矣。”",
            "子曰：“古者民有三疾，今也或是之亡也。古之狂也肆，今之狂也荡；古之矜也廉，今之矜也忿戾；古之愚也直，今之愚也诈而已矣。”",
            "子曰：“巧言令色，鲜矣仁。”",
            "子曰：“恶紫之夺朱也，恶郑声之乱雅乐也，恶利口之覆邦家者。”",
            "子曰：“予欲无言。”子贡曰：“子如不言，则小子何述焉？”子曰：“天何言哉？四时行焉，百物生焉，天何言哉？”",
            "孺悲欲见孔子，孔子辞以疾。将命者出户，取瑟而歌，使之闻之。",
            "宰我问：“三年之丧，期已久矣！君子三年不为礼，礼必坏；三年不为乐，乐必崩。旧谷既没，新谷既升，钻燧改火，期可已矣。”子曰：“食夫稻，衣夫锦，于女安乎？”曰：“安！”“女安则为之！夫君子之居丧，食旨不甘，闻乐不乐，居处不安，故不为也。今女安，则为之！”宰我出，子曰：“予之不仁也！子生三年，然后免于父母之怀。夫三年之丧，天下之通丧也，予也有三年之爱于其父母乎！”",
            "子曰：“饱食终日，无所用心，难矣哉！不有博弈者乎？为之犹贤乎已。”",
            "子路曰：“君子尚勇乎？”子曰：“君子义以为上。君子有勇而无义为乱，小人有勇而无义为盗。”",
            "子贡曰：“君子亦有恶乎？”子曰：“有恶。恶称人之恶者，恶居下流而讪上者，恶勇而无礼者，恶果敢而窒者。”曰：“赐也亦有恶乎？”“恶徼以为知者，恶不孙以为勇者，恶讦以为直者。”",
            "子曰：“唯女子与小人为难养也，近之则不孙，远之则怨。”",
            "子曰：“年四十而见恶焉，其终也已。”",
          ],
        },
        {
          chapter: "微子篇",
          paragraphs: [
            "微子去之，箕子为之奴，比干谏而死。孔子曰：“殷有三仁焉。”",
            "柳下惠为士师，三黜。人曰：“子未可以去乎？”曰：“直道而事人，焉往而不三黜？枉道而事人，何必去父母之邦？”",
            "齐景公待孔子曰：“若季氏，则吾不能。”以季、孟之间待之，曰：“吾老矣，不能用也。”孔子行。",
            "齐人归女乐，季桓子受之，三日不朝，孔子行。”",
            "楚狂接舆歌而过孔子曰：“凤兮凤兮，何德之衰？往者不可谏，来者犹可追。已而已而，今之从政者殆而！”孔子下，欲与之言，趋而辟之，不得与之言。",
            "长沮、桀溺耦而耕，孔子过之，使子路问津焉。长沮曰：“夫执舆者为谁？”子路曰：“为孔丘。”曰：“是鲁孔丘与？”曰：“是也。”曰：“是知津矣。”问于桀溺，桀溺曰：“子为谁？”曰：“为仲由。”曰：“是鲁孔丘之徒与？”对曰：“然。”曰：“滔滔者天下皆是也，而谁以易之？且而与其从辟人之士也，岂若从辟世之士？”耰而不辍。子路行以告，夫子怃然曰：“鸟兽不可与同群，吾非斯人之徒与而谁与？天下有道，丘不与易也。”",
            "子路从而后，遇丈人，以杖荷蓧。子路问曰：“子见夫子乎？”丈人曰：“四体不勤，五谷不分，孰为夫子？”植其杖而芸，子路拱而立。止子路宿，杀鸡为黍而食之，见其二子焉。明日，子路行以告，子曰：“隐者也。”使子路反见之，至则行矣。子路曰：“不仕无义。长幼之节不可废也，君臣之义如之何其废之？欲洁其身而乱大伦。君子之仕也，行其义也，道之不行已知之矣。”",
            "逸民：伯夷、叔齐、虞仲、夷逸、朱张、柳下惠、少连。子曰：“不降其志，不辱其身，伯夷、叔齐与！”谓：“柳下惠、少连降志辱身矣，言中伦，行中虑，其斯而已矣。”谓：“虞仲、夷逸隐居放言，身中清，废中权。我则异于是，无可无不可。”",
            "太师挚适齐，亚饭干适楚，三饭缭适蔡，四饭缺适秦，鼓方叔入于河，播鼗武入于汉，少师阳、击磬襄入于海。",
            "周公谓鲁公曰：“君子不施其亲，不使大臣怨乎不以，故旧无大故则不弃也，无求备于一人。”",
            "周有八士：伯达、伯适、仲突、仲忽、叔夜、叔夏、季随、季騧。",
          ],
        },
        {
          chapter: "子张篇",
          paragraphs: [
            "子张曰：“士见危致命，见得思义，祭思敬，丧思哀，其可已矣。”",
            "子张曰：“执德不弘，信道不笃，焉能为有？焉能为亡？”",
            "子夏之门人问交于子张，子张曰：“子夏云何？”对曰：“子夏曰：‘可者与之，其不可者拒之。’”子张曰：“异乎吾所闻。君子尊贤而容众，嘉善而矜不能。我之大贤与，于人何所不容？我之不贤与，人将拒我，如之何其拒人也？”",
            "子夏曰：“虽小道必有可观者焉，致远恐泥，是以君子不为也。”",
            "子夏曰：“日知其所亡，月无忘其所能，可谓好学也已矣。”",
            "子夏曰：“博学而笃志，切问而近思，仁在其中矣。”",
            "子夏曰：“百工居肆以成其事，君子学以致其道。”",
            "子夏曰：“小人之过也必文。”",
            "子夏曰：“君子有三变：望之俨然，即之也温，听其言也厉。”",
            "子夏曰：“君子信而后劳其民，未信，则以为厉己也；信而后谏，未信，则以为谤己也。”",
            "子夏曰：“大德不逾闲，小德出入可也。”",
            "子游曰：“子夏之门人小子，当洒扫应对进退则可矣。抑末也，本之则无，如之何？”子夏闻之，曰：“噫，言游过矣！君子之道，孰先传焉？孰后倦焉？譬诸草木，区以别矣。君子之道焉可诬也？有始有卒者，其惟圣人乎！”",
            "子夏曰：“仕而优则学，学而优则仕。”",
            "子游曰：“丧致乎哀而止。”",
            "子游曰：“吾友张也为难能也，然而未仁。”",
            "曾子曰：“堂堂乎张也，难与并为仁矣。”",
            "曾子曰：“吾闻诸夫子，人未有自致者也，必也亲丧乎！”",
            "曾子曰：“吾闻诸夫子，孟庄子之孝也，其他可能也；其不改父之臣与父之政，是难能也。”",
            "孟氏使阳肤为士师，问于曾子。曾子曰：“上失其道，民散久矣。如得其情，则哀矜而勿喜！”",
            "子贡曰：“纣之不善，不如是之甚也。是以君子恶居下流，天下之恶皆归焉。”",
            "子贡曰：“君子之过也，如日月之食焉。过也人皆见之，更也人皆仰之。”",
            "卫公孙朝问于子贡曰：“仲尼焉学？”子贡曰：“文武之道未坠于地，在人。贤者识其大者，不贤者识其小者，莫不有文武之道焉，夫子焉不学？而亦何常师之有？”",
            "叔孙武叔语大夫于朝曰：“子贡贤于仲尼。”子服景伯以告子贡，子贡曰：“譬之宫墙，赐之墙也及肩，窥见室家之好；夫子之墙数仞，不得其门而入，不见宗庙之美、百官之富。得其门者或寡矣，夫子之云不亦宜乎！”",
            "叔孙武叔毁仲尼，子贡曰：“无以为也，仲尼不可毁也。他人之贤者，丘陵也，犹可逾也；仲尼，日月也，无得而逾焉。人虽欲自绝，其何伤于日月乎？多见其不知量也。”",
            "陈子禽谓子贡曰：“子为恭也，仲尼岂贤于子乎？”子贡曰：“君子一言以为知，一言以为不知，言不可不慎也。夫子之不可及也，犹天之不可阶而升也。夫子之得邦家者，所谓立之斯立，道之斯行，绥之斯来，动之斯和。其生也荣，其死也哀，如之何其可及也？”",
          ],
        },
        {
          chapter: "尧曰篇",
          paragraphs: [
            "尧曰：“咨！尔舜，天之历数在尔躬，允执其中。四海困穷，天禄永终。”舜亦以命禹。曰：“予小子履，敢用玄牡，敢昭告于皇皇后帝：有罪不敢赦，帝臣不蔽，简在帝心。朕躬有罪，无以万方；万方有罪，罪在朕躬。”周有大赉，善人是富。“虽有周亲，不如仁人。百姓有过，在予一人。”谨权量，审法度，修废官，四方之政行焉。兴灭国，继绝世，举逸民，天下之民归心焉。所重：民、食、丧、祭。宽则得众，信则民任焉，敏则有功，公则说。",
            "子张问于孔子曰：“何如斯可以从政矣？”子曰：“尊五美，屏四恶，斯可以从政矣。”子张曰：“何谓五美？”子曰：“君子惠而不费，劳而不怨，欲而不贪，泰而不骄，威而不猛。”子张曰：“何谓惠而不费？”子曰：“因民之所利而利之，斯不亦惠而不费乎？择可劳而劳之，又谁怨？欲仁而得仁，又焉贪？君子无众寡，无小大，无敢慢，斯不亦泰而不骄乎？君子正其衣冠，尊其瞻视，俨然人望而畏之，斯不亦威而不猛乎？”子张曰：“何谓四恶？”子曰：“不教而杀谓之虐；不戒视成谓之暴；慢令致期谓之贼；犹之与人也，出纳之吝谓之有司。”",
            "孔子曰：“不知命，无以为君子也；不知礼，无以立也；不知言，无以知人也。”",
          ],
        },
      ],
    },
    {
      sctitle: "孟子",
      tctitle: "孟子",
      entitle: "Mengzi (Mencius)",
      othertitle: "The Works of Mencius",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Mengzi",
      school: "Confucianism",
      dynasty: "Spring and Autumn, Warring States",
      year: "340 BC-250 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [
      ]
    },
    {
      sctitle: "礼记",
      tctitle: "禮記",
      entitle: "Book of Rites",
      othertitle: "Classic of Rites, 小戴禮記",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "Confucianism",
      dynasty: "Spring and Autumn, Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "荀子",
      tctitle: "荀子",
      entitle: "Xunzi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Xunzi",
      school: "Confucianism",
      dynasty: "Spring and Autumn, Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "孝經",
      entitle: "Xiao Jing (Classic of Filial Piety)",
      othertitle: "The Classic of Filial Piety",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "Confucianism",
      dynasty: "Spring and Autumn, Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "說苑",
      entitle: "Shuo Yuan (Garden of Stories)",
      othertitle: "Garden of Persuasion, Garden of Talks",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Liu Xiang",
      school: "Confucianism",
      dynasty: "Western Han",
      year: "206 BC - 9",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "春秋繁露",
      entitle: "Chun Qiu Fan Lu",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Dong Zhong Shu",
      school: "Confucianism",
      dynasty: "Western Han",
      year: "206 BC - 9",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
  
    {
      sctitle: "",
      tctitle: "莊子",
      entitle: "Zhuangzi",
      othertitle: "南華真經",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Zhuangzi",
      school: "Taoism",
      dynasty: "Warring States",
      year: "350 BC-250 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "道德經",
      entitle: "Dao De Jing",
      othertitle: "Tao Te Ching, 老子, Laozi",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Laozi",
      school: "Taoism",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "列子",
      entitle: "Liezi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "Taoism",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "鶡冠子",
      entitle: "He Guan Zi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      enauthor: "",
      school: "Taoism",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
  
    {
      sctitle: "",
      tctitle: "韓非子",
      entitle: "Hanfeizi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "Legalism",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "管子",
      entitle: "Guanzi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "Legalism",
      dynasty: "Warring States - Han",
      year: "475 BC - 220",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
  
    {
      sctitle: "",
      tctitle: "商君書",
      entitle: "Shang Jun Shu (Book of Lord Shang)",
      othertitle: "商子",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "Legalism",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "諫逐客書",
      entitle: "Jian Zhu Ke Shu",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Li Si",
      school: "Legalism",
      dynasty: "Warring States",
      year: "237 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "慎子",
      entitle: "Shenzi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Shen Dao",
      school: "Legalism",
      dynasty: "Warring States",
      year: "237 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "孫子兵法",
      entitle: "The Art of War",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Sun Wu",
      school: "School of the Military",
      dynasty: "Spring and Autumn",
      year: "515 BC-512 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "六韜",
      entitle: "Liu Tao",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "School of the Military",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "三略",
      entitle: "Three Strategies",
      othertitle: "黃石公三略",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "School of the Military",
      dynasty: "Western Han",
      year: "100 BC-9",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "吳子",
      entitle: "Wu Zi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Wu Qi",
      school: "School of the Military",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "司馬法",
      entitle: "Si Ma Fa",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "School of the Military",
      dynasty: "Spring and Autumn - Warring States",
      year: "772 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "尉繚子",
      entitle: "Wei Liao Zi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "School of the Military",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
  
    {
      sctitle: "墨子",
      tctitle: "墨子",
      entitle: "Mozi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Mozi",
      school: "Mohism",
      dynasty: "Spring and Autumn, Warring States",
      year: "490 BC-221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
  
    {
      sctitle: "",
      tctitle: "魯勝墨辯注敘",
      entitle: "Mo Bian Zhu Xu",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Lu Sheng",
      school: "Mohism",
      dynasty: "Western Jin",
      year: "265 - 317",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "公孫龍子",
      entitle: "Gongsunlongzi",
      othertitle: "",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "School of Names",
      dynasty: "Warring States",
      year: "475 BC - 221 BC",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "淮南子",
      entitle: "Huainanzi",
      othertitle: "淮南, 鴻烈",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "",
      school: "Syncretism",
      dynasty: "Western Han",
      year: "206 BC - 9",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
    {
      sctitle: "",
      tctitle: "呂氏春秋",
      entitle: "Lü Shi Chun Qiu",
      othertitle: "呂覽",
      scauthor: "", 
      tcauthor: "", 
      enauthor: "Lu Buwei",
      school: "Syncretism",
      dynasty: "Warring States",
      year: "247 BC-239 BC ",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [], 
    },
  
    {
      sctitle: "百家姓",
      tctitle: "百家姓",
      entitle: "Hundred Family Surnames",
      othertitle: "",
      scauthor: "",
      tcauthor: "周兴嗣",
      enauthor: "",
      school: "General",
      dynasty: "北宋",
      year: "",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [], 
      content: [
        {
          chapter: "全文",
          paragraphs: [
            "趙錢孫李，周吳鄭王。",
            "馮陳褚衛，蔣沈韓楊。",
            "朱秦尤許，何呂施張。",
            "孔曹嚴華，金魏陶姜。",
            "戚謝鄒喻，柏水竇章。",
            "雲蘇潘葛，奚範彭郎。",
            "魯韋昌馬，苗鳳花方。",
            "俞任袁柳，酆鮑史唐。",
            "費廉岑薛，雷賀倪湯。",
            "滕殷羅畢，郝鄔安常。",
            "樂于時傅，皮卞齊康。",
            "伍余元蔔，顧孟平黃。",
            "和穆蕭尹，姚邵湛汪。",
            "祁毛禹狄，米貝明臧。",
            "計伏成戴，談宋茅龐。",
            "熊紀舒屈，項祝董梁。",
            "杜阮藍閔，席季麻強。",
            "賈路婁危，江童顏郭。",
            "梅盛林刁，鐘徐邱駱。",
            "高夏蔡田，樊胡淩霍。",
            "虞萬支柯，昝管盧莫。",
            "經房裘繆，幹解應宗。",
            "丁宣賁鄧，郁單杭洪。",
            "包諸左石，崔吉鈕龔。",
            "程嵇邢滑，裴陸榮翁。",
            "荀羊於惠，甄麴家封。",
            "芮羿儲靳，汲邴糜松。",
            "井段富巫，烏焦巴弓。",
            "牧隗山谷，車侯宓蓬。",
            "全郗班仰，秋仲伊宮。",
            "寧仇欒暴，甘鈄厲戎。",
            "祖武符劉，景詹束龍。",
            "葉幸司韶，郜黎薊薄。",
            "印宿白懷，蒲邰從鄂。",
            "索鹹籍賴，卓藺屠蒙。",
            "池喬陰鬱，胥能蒼雙。",
            "聞莘黨翟，譚貢勞逄。",
            "姬申扶堵，冉宰酈雍。",
            "郤璩桑桂，濮牛壽通。",
            "邊扈燕冀，郟浦尚農。",
            "溫別莊晏，柴瞿閻充。",
            "慕連茹習，宦艾魚容。",
            "向古易慎，戈廖庾終。",
            "暨居衡步，都耿滿弘。",
            "匡國文寇，廣祿闕東。",
            "歐殳沃利，蔚越夔隆。",
            "師鞏厙聶，晁勾敖融。",
            "冷訾辛闞，那簡饒空。",
            "曾毋沙乜，養鞠須豐。",
            "巢關蒯相，查后荊紅。",
            "遊竺權逯，蓋益桓公。",
            "萬俟司馬，上官歐陽。",
            "夏侯諸葛，聞人東方。",
            "赫連皇甫，尉遲公羊。",
            "澹台公冶，宗政濮陽。",
            "淳于單于，太叔申屠。",
            "公孫仲孫，軒轅令狐。",
            "鐘離宇文，長孫慕容。",
            "鮮于閭丘，司徒司空。",
            "亓官司寇，仉督子車。",
            "顓孫端木，巫馬公西。",
            "漆雕樂正，壤駟公良。",
            "拓跋夾谷，宰父谷梁。",
            "晉楚閆法，汝鄢塗欽。",
            "段幹百裏，東郭南門。",
            "呼延歸海，羊舌微生。",
            "嶽帥緱亢，況后有琴。",
            "梁丘左丘，東門西門。",
            "商牟佘佴，伯賞南宮。",
            "墨哈譙笪，年愛陽佟。",
            "第五言福，百家姓終。",
          ],
          origin: [
            { surname: "趙", place: "天水" },
            { surname: "錢", place: "彭城" },
            { surname: "孫", place: "樂安" },
            { surname: "李", place: "隴西" },
            { surname: "周", place: "汝南" },
            { surname: "吳", place: "延陵" },
            { surname: "鄭", place: "滎陽" },
            { surname: "王", place: "太原" },
            { surname: "馮", place: "始平" },
            { surname: "陳", place: "潁川" },
            { surname: "褚", place: "河南" },
            { surname: "衛", place: "河東" },
            { surname: "蔣", place: "樂安" },
            { surname: "沈", place: "吳興" },
            { surname: "韓", place: "南陽" },
            { surname: "楊", place: "弘農" },
            { surname: "朱", place: "沛郡" },
            { surname: "秦", place: "天水" },
            { surname: "尤", place: "吳興" },
            { surname: "許", place: "高陽" },
            { surname: "何", place: "廬江" },
            { surname: "呂", place: "河東" },
            { surname: "施", place: "吳興" },
            { surname: "張", place: "清河" },
            { surname: "孔", place: "魯郡" },
            { surname: "曹", place: "譙郡" },
            { surname: "嚴", place: "天水" },
            { surname: "華", place: "武陵" },
            { surname: "金", place: "彭城" },
            { surname: "魏", place: "鉅鹿" },
            { surname: "陶", place: "濟陽" },
            { surname: "姜", place: "天水" },
            { surname: "戚", place: "東海" },
            { surname: "謝", place: "陳留" },
            { surname: "鄒", place: "范陽" },
            { surname: "喻", place: "江夏" },
            { surname: "柏", place: "魏郡" },
            { surname: "水", place: "吳興" },
            { surname: "竇", place: "挾風" },
            { surname: "章", place: "河間" },
            { surname: "雲", place: "琅琊" },
            { surname: "蘇", place: "武功" },
            { surname: "潘", place: "滎陽" },
            { surname: "葛", place: "頓丘" },
            { surname: "奚", place: "北海" },
            { surname: "範", place: "高平" },
            { surname: "彭", place: "隴西" },
            { surname: "郎", place: "中山" },
            { surname: "魯", place: "扶風" },
            { surname: "韋", place: "京兆" },
            { surname: "昌", place: "汝南" },
            { surname: "馬", place: "扶風" },
            { surname: "苗", place: "東陽" },
            { surname: "鳳", place: "谷陽" },
            { surname: "花", place: "東平" },
            { surname: "方", place: "河南" },
            { surname: "俞", place: "河間" },
            { surname: "任", place: "樂安" },
            { surname: "袁", place: "汝南" },
            { surname: "柳", place: "河東" },
            { surname: "酆", place: "京兆" },
            { surname: "鮑", place: "上黨" },
            { surname: "史", place: "京兆" },
            { surname: "唐", place: "晉昌" },
            { surname: "費", place: "江夏" },
            { surname: "廉", place: "河東" },
            { surname: "岑", place: "南陽" },
            { surname: "薛", place: "河東" },
            { surname: "雷", place: "馮翊" },
            { surname: "賀", place: "廣平" },
            { surname: "倪", place: "千乘" },
            { surname: "湯", place: "中山" },
            { surname: "滕", place: "南陽" },
            { surname: "殷", place: "汝南" },
            { surname: "羅", place: "豫章" },
            { surname: "畢", place: "河南" },
            { surname: "郝", place: "太原" },
            { surname: "鄔", place: "太原" },
            { surname: "安", place: "武陵" },
            { surname: "常", place: "平原" },
            { surname: "樂", place: "南陽" },
            { surname: "于", place: "河內" },
            { surname: "時", place: "隴西" },
            { surname: "傅", place: "清河" },
            { surname: "皮", place: "天水" },
            { surname: "卞", place: "濟陽" },
            { surname: "齊", place: "汝南" },
            { surname: "康", place: "京兆" },
            { surname: "伍", place: "安定" },
            { surname: "余", place: "下邳" },
            { surname: "元", place: "河南" },
            { surname: "卜", place: "西河" },
            { surname: "顧", place: "武陵" },
            { surname: "孟", place: "平陸" },
            { surname: "平", place: "河內" },
            { surname: "黃", place: "江夏" },
            { surname: "和", place: "汝南" },
            { surname: "穆", place: "河南" },
            { surname: "蕭", place: "蘭陵" },
            { surname: "尹", place: "天水" },
            { surname: "姚", place: "吳興" },
            { surname: "邵", place: "博陵" },
            { surname: "湛", place: "豫章" },
            { surname: "汪", place: "平陽" },
            { surname: "祁", place: "太原" },
            { surname: "毛", place: "西河" },
            { surname: "禹", place: "隴西" },
            { surname: "狄", place: "天水" },
            { surname: "米", place: "京兆" },
            { surname: "貝", place: "清河" },
            { surname: "明", place: "吳興" },
            { surname: "臧", place: "東海" },
            { surname: "計", place: "京兆" },
            { surname: "伏", place: "太原" },
            { surname: "成", place: "上谷" },
            { surname: "戴", place: "譙郡" },
            { surname: "談", place: "廣平" },
            { surname: "宋", place: "京兆" },
            { surname: "茅", place: "東海" },
            { surname: "龐", place: "始平" },
            { surname: "熊", place: "江陵" },
            { surname: "紀", place: "平陽" },
            { surname: "舒", place: "京兆" },
            { surname: "屈", place: "臨海" },
            { surname: "項", place: "遼西" },
            { surname: "祝", place: "太原" },
            { surname: "董", place: "隴西" },
            { surname: "梁", place: "安定" },
            { surname: "杜", place: "京兆" },
            { surname: "阮", place: "陳留" },
            { surname: "藍", place: "汝南" },
            { surname: "閔", place: "隴西" },
            { surname: "席", place: "安定" },
            { surname: "季", place: "渤海" },
            { surname: "麻", place: "上谷" },
            { surname: "強", place: "天水" },
            { surname: "賈", place: "武威" },
            { surname: "路", place: "內黃" },
            { surname: "婁", place: "譙郡" },
            { surname: "危", place: "汝南" },
            { surname: "江", place: "濟陽" },
            { surname: "童", place: "雁門" },
            { surname: "顏", place: "魯郡" },
            { surname: "郭", place: "太原" },
            { surname: "梅", place: "汝南" },
            { surname: "盛", place: "汝南" },
            { surname: "林", place: "西河" },
            { surname: "刁", place: "弘農" },
            { surname: "鐘", place: "潁川" },
            { surname: "徐", place: "東海" },
            { surname: "丘", place: "河南" },
            { surname: "駱", place: "內黃" },
            { surname: "高", place: "渤海" },
            { surname: "夏", place: "會稽" },
            { surname: "蔡", place: "濟陽" },
            { surname: "田", place: "渤海" },
            { surname: "樊", place: "上黨" },
            { surname: "胡", place: "安定" },
            { surname: "凌", place: "河間" },
            { surname: "霍", place: "太原" },
            { surname: "虞", place: "陳留" },
            { surname: "萬", place: "扶風" },
            { surname: "支", place: "邰陽" },
            { surname: "柯", place: "濟陽" },
            { surname: "昝", place: "太原" },
            { surname: "管", place: "晉陽" },
            { surname: "盧", place: "范陽" },
            { surname: "莫", place: "鉅鹿" },
            { surname: "經", place: "滎陽" },
            { surname: "房", place: "清河" },
            { surname: "裘", place: "渤海" },
            { surname: "繆", place: "蘭陵" },
            { surname: "乾", place: "潁川" },
            { surname: "解", place: "平陽" },
            { surname: "應", place: "汝南" },
            { surname: "宗", place: "京兆" },
            { surname: "丁", place: "濟陽" },
            { surname: "宣", place: "始平" },
            { surname: "賁", place: "宣城" },
            { surname: "鄧", place: "南陽" },
            { surname: "郁", place: "黎陽" },
            { surname: "單", place: "南安" },
            { surname: "杭", place: "余杭" },
            { surname: "洪", place: "豫章" },
            { surname: "包", place: "上黨" },
            { surname: "諸", place: "琅琊" },
            { surname: "左", place: "濟陽" },
            { surname: "石", place: "武威" },
            { surname: "崔", place: "博陵" },
            { surname: "吉", place: "馮翊" },
            { surname: "鈕", place: "吳興" },
            { surname: "龔", place: "武陵" },
            { surname: "程", place: "安定" },
            { surname: "嵇", place: "譙郡" },
            { surname: "邢", place: "河間" },
            { surname: "滑", place: "下邳" },
            { surname: "裴", place: "河東" },
            { surname: "陸", place: "吳郡" },
            { surname: "榮", place: "上谷" },
            { surname: "翁", place: "錢塘" },
            { surname: "荀", place: "河內" },
            { surname: "羊", place: "京兆" },
            { surname: "於", place: "京兆" },
            { surname: "惠", place: "扶風" },
            { surname: "甄", place: "中山" },
            { surname: "麴", place: "汝南" },
            { surname: "家", place: "京兆" },
            { surname: "封", place: "渤海" },
            { surname: "芮", place: "平原" },
            { surname: "羿", place: "齊郡" },
            { surname: "儲", place: "河東" },
            { surname: "靳", place: "河西" },
            { surname: "汲", place: "清河" },
            { surname: "邴", place: "平陽" },
            { surname: "糜", place: "汝南" },
            { surname: "松", place: "東莞" },
            { surname: "井", place: "扶風" },
            { surname: "段", place: "京兆" },
            { surname: "富", place: "齊郡" },
            { surname: "巫", place: "平陽" },
            { surname: "烏", place: "潁川" },
            { surname: "焦", place: "中山" },
            { surname: "巴", place: "高平" },
            { surname: "弓", place: "太原" },
            { surname: "牧", place: "弘農" },
            { surname: "隗", place: "余杭" },
            { surname: "山", place: "河南" },
            { surname: "谷", place: "上谷" },
            { surname: "車", place: "京兆" },
            { surname: "侯", place: "上谷" },
            { surname: "宓", place: "平昌" },
            { surname: "蓬", place: "長樂" },
            { surname: "全", place: "京兆" },
            { surname: "郗", place: "山陽" },
            { surname: "班", place: "扶風" },
            { surname: "仰", place: "汝南" },
            { surname: "秋", place: "天水" },
            { surname: "仲", place: "中山" },
            { surname: "伊", place: "陳留" },
            { surname: "宮", place: "太原" },
            { surname: "寧", place: "齊郡" },
            { surname: "仇", place: "平陽" },
            { surname: "欒", place: "西河" },
            { surname: "暴", place: "魏郡" },
            { surname: "甘", place: "渤海" },
            { surname: "鈄", place: "遼西" },
            { surname: "厲", place: "南陽" },
            { surname: "戎", place: "江陵" },
            { surname: "祖", place: "范陽" },
            { surname: "武", place: "太原" },
            { surname: "符", place: "琅琊" },
            { surname: "劉", place: "彭城" },
            { surname: "景", place: "晉陽" },
            { surname: "詹", place: "河間" },
            { surname: "束", place: "南陽" },
            { surname: "龍", place: "武陵" },
            { surname: "葉", place: "南陽" },
            { surname: "幸", place: "雁門" },
            { surname: "司", place: "頓丘" },
            { surname: "韶", place: "太原" },
            { surname: "郜", place: "京兆" },
            { surname: "黎", place: "京兆" },
            { surname: "薊", place: "內黃" },
            { surname: "薄", place: "雁門" },
            { surname: "印", place: "馮翊" },
            { surname: "宿", place: "東平" },
            { surname: "白", place: "南陽" },
            { surname: "懷", place: "河內" },
            { surname: "蒲", place: "河東" },
            { surname: "邰", place: "平盧" },
            { surname: "從", place: "東莞" },
            { surname: "鄂", place: "武昌" },
            { surname: "索", place: "武威" },
            { surname: "咸", place: "汝南" },
            { surname: "籍", place: "廣平" },
            { surname: "賴", place: "潁川" },
            { surname: "卓", place: "西河" },
            { surname: "藺", place: "中山" },
            { surname: "屠", place: "陳留" },
            { surname: "蒙", place: "安定" },
            { surname: "池", place: "河西" },
            { surname: "喬", place: "梁郡" },
            { surname: "陰", place: "始興" },
            { surname: "鬱", place: "太原" },
            { surname: "胥", place: "琅琊" },
            { surname: "能", place: "太原" },
            { surname: "蒼", place: "武陵" },
            { surname: "雙", place: "天水" },
            { surname: "聞", place: "吳興" },
            { surname: "莘", place: "天水" },
            { surname: "黨", place: "馮翊" },
            { surname: "翟", place: "南陽" },
            { surname: "譚", place: "齊郡" },
            { surname: "貢", place: "廣平" },
            { surname: "勞", place: "武陽" },
            { surname: "逢", place: "譙郡" },
            { surname: "姬", place: "南陽" },
            { surname: "申", place: "琅琊" },
            { surname: "扶", place: "京兆" },
            { surname: "堵", place: "河東" },
            { surname: "冉", place: "武陵" },
            { surname: "宰", place: "西河" },
            { surname: "酈", place: "新蔡" },
            { surname: "雍", place: "京兆" },
            { surname: "郤", place: "濟陽" },
            { surname: "璩", place: "豫章" },
            { surname: "桑", place: "黎陽" },
            { surname: "桂", place: "天水" },
            { surname: "濮", place: "魯郡" },
            { surname: "牛", place: "隴西" },
            { surname: "壽", place: "京兆" },
            { surname: "通", place: "西河" },
            { surname: "邊", place: "隴西" },
            { surname: "扈", place: "京兆" },
            { surname: "燕", place: "范陽" },
            { surname: "冀", place: "渤海" },
            { surname: "郟", place: "武陵" },
            { surname: "浦", place: "京兆" },
            { surname: "尚", place: "上黨" },
            { surname: "農", place: "雁門" },
            { surname: "溫", place: "太原" },
            { surname: "別", place: "京兆" },
            { surname: "莊", place: "天水" },
            { surname: "晏", place: "齊郡" },
            { surname: "柴", place: "平陽" },
            { surname: "瞿", place: "松陽" },
            { surname: "閻", place: "太原" },
            { surname: "充", place: "太原" },
            { surname: "慕", place: "敦煌" },
            { surname: "連", place: "上黨" },
            { surname: "茹", place: "河內" },
            { surname: "習", place: "東陽" },
            { surname: "宦", place: "東陽" },
            { surname: "艾", place: "天水" },
            { surname: "魚", place: "雁門" },
            { surname: "容", place: "敦煌" },
            { surname: "向", place: "河南" },
            { surname: "古", place: "新安" },
            { surname: "易", place: "太原" },
            { surname: "慎", place: "天水" },
            { surname: "戈", place: "臨海" },
            { surname: "廖", place: "汝南" },
            { surname: "庚", place: "濟陽" },
            { surname: "終", place: "南陽" },
            { surname: "暨", place: "渤海" },
            { surname: "居", place: "渤海" },
            { surname: "衡", place: "雁門" },
            { surname: "步", place: "平陽" },
            { surname: "都", place: "黎陽" },
            { surname: "耿", place: "高陽" },
            { surname: "滿", place: "河東" },
            { surname: "弘", place: "太原" },
            { surname: "匡", place: "晉陽" },
            { surname: "國", place: "下邳" },
            { surname: "文", place: "雁門" },
            { surname: "寇", place: "上谷" },
            { surname: "廣", place: "丹陽" },
            { surname: "祿", place: "扶風" },
            { surname: "闕", place: "下邳" },
            { surname: "東", place: "平原" },
            { surname: "歐", place: "平陽" },
            { surname: "殳", place: "武功" },
            { surname: "沃", place: "太原" },
            { surname: "利", place: "河南" },
            { surname: "蔚", place: "琅琊" },
            { surname: "越", place: "晉陽" },
            { surname: "夔", place: "京兆" },
            { surname: "隆", place: "南陽" },
            { surname: "師", place: "太原" },
            { surname: "鞏", place: "山陽" },
            { surname: "厙", place: "河南" },
            { surname: "聶", place: "河東" },
            { surname: "晁", place: "京兆" },
            { surname: "勾", place: "平陽" },
            { surname: "敖", place: "譙郡" },
            { surname: "融", place: "南康" },
            { surname: "冷", place: "京兆" },
            { surname: "訾", place: "渤海" },
            { surname: "辛", place: "隴西" },
            { surname: "闞", place: "天水" },
            { surname: "那", place: "天水" },
            { surname: "簡", place: "范陽" },
            { surname: "饒", place: "平陽" },
            { surname: "空", place: "營邱" },
            { surname: "曾", place: "魯郡" },
            { surname: "毋", place: "鉅鹿" },
            { surname: "沙", place: "汝南" },
            { surname: "乜", place: "晉昌" },
            { surname: "養", place: "山陽" },
            { surname: "鞠", place: "汝南" },
            { surname: "須", place: "渤海" },
            { surname: "豐", place: "松陽" },
            { surname: "巢", place: "彭城" },
            { surname: "關", place: "隴西" },
            { surname: "蒯", place: "襄陽" },
            { surname: "相", place: "西河" },
            { surname: "查", place: "齊郡" },
            { surname: "后", place: "東海" },
            { surname: "荊", place: "廣陵" },
            { surname: "紅", place: "平昌" },
            { surname: "游", place: "廣平" },
            { surname: "竺", place: "東海" },
            { surname: "權", place: "天水" },
            { surname: "逯", place: "廣平" },
            { surname: "蓋", place: "汝南" },
            { surname: "益", place: "馮翊" },
            { surname: "桓", place: "譙郡" },
            { surname: "公", place: "括蒼" },
            { surname: "萬俟", place: "蘭陵" },
            { surname: "司馬", place: "河內" },
            { surname: "上官", place: "天水" },
            { surname: "歐陽", place: "渤海" },
            { surname: "夏侯", place: "譙郡" },
            { surname: "諸葛", place: "琅琊" },
            { surname: "聞人", place: "河南" },
            { surname: "東方", place: "濟南" },
            { surname: "赫連", place: "渤海" },
            { surname: "皇甫", place: "京兆" },
            { surname: "尉遲", place: "太原" },
            { surname: "公羊", place: "頓丘" },
            { surname: "澹台", place: "太原" },
            { surname: "公冶", place: "魯郡" },
            { surname: "宗政", place: "彭城" },
            { surname: "濮陽", place: "博陵" },
            { surname: "淳于", place: "河內" },
            { surname: "單于", place: "千乘" },
            { surname: "太叔", place: "東平" },
            { surname: "申屠", place: "京兆" },
            { surname: "公孫", place: "高陽" },
            { surname: "仲孫", place: "高陽" },
            { surname: "軒轅", place: "邰陽" },
            { surname: "令狐", place: "太原" },
            { surname: "鐘離", place: "會稽" },
            { surname: "宇文", place: "趙郡" },
            { surname: "長孫", place: "濟陽" },
            { surname: "慕容", place: "敦煌" },
            { surname: "司徒", place: "趙郡" },
            { surname: "司空", place: "頓丘" },
          ],
        },
      ],
    },
    {
      sctitle: "弟子规",
      tctitle: "弟子規",
      pytitle: "Di Zi Gui",
      entitle: "Guidelines for Children",
      othertitle: "",
      scauthor: "李毓秀",
      tcauthor: "李毓秀",
      enauthor: "",
      school: "General",
      dynasty: "",
      year: "",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [],
      content: [
        {
          chapter: "總敘",
          paragraphs: [
            "弟子規 聖人訓 首孝弟 次謹信",
            "泛愛眾 而親仁 有餘力 則學文",
          ],
        },
        {
          chapter: "入則孝",
          paragraphs: [
            "父母呼 應勿緩 父母命 行勿懶",
            "父母教 須敬聽 父母責 須順承",
            "冬則溫 夏則凊 晨則省 昏則定",
            "出必告 反必面 居有常 業無變",
            "事雖小 勿擅為 苟擅為 子道虧",
            "物雖小 勿私藏 苟私藏 親心傷",
            "親所好 力為具 親所惡 謹為去",
            "身有傷 貽親憂 德有傷 貽親羞",
            "親愛我 孝何難 親憎我 孝方賢",
            "親有過 諫使更 怡吾色 柔吾聲",
            "諫不入 悅複諫 號泣隨 撻無怨",
            "親有疾 藥先嘗 晝夜侍 不離床",
            "喪三年 常悲咽 居處變 酒肉絕",
            "喪盡禮 祭盡誠 事死者 如事生",
          ],
        },
        {
          chapter: "出則弟",
          paragraphs: [
            "兄道友 弟道恭 兄弟睦 孝在中",
            "財物輕 怨何生 言語忍 忿自泯",
            "或飲食 或坐走 長者先 幼者後",
            "長呼人 即代叫 人不在 己即到",
            "稱尊長 勿呼名 對尊長 勿見能",
            "路遇長 疾趨揖 長無言 退恭立",
            "騎下馬 乘下車 過猶待 百步余",
            "長者立 幼勿坐 長者坐 命乃坐",
            "尊長前 聲要低 低不聞 卻非宜",
            "進必趨 退必遲 問起對 視勿移",
            "事諸父 如事父 事諸兄 如事兄",
          ],
        },
        {
          chapter: "謹",
          paragraphs: [
            "朝起早 夜眠遲 老易至 惜此時",
            "晨必盥 兼漱口 便溺回 輒淨手",
            "冠必正 紐必結 襪與履 俱緊切",
            "置冠服 有定位 勿亂頓 致污穢",
            "衣貴潔 不貴華 上循分 下稱家",
            "對飲食 勿揀擇 食適可 勿過則",
            "年方少 勿飲酒 飲酒醉 最為醜",
            "步從容 立端正 揖深圓 拜恭敬",
            "勿踐閾 勿跛倚 勿箕踞 勿搖髀",
            "緩揭簾 勿有聲 寬轉彎 勿觸棱",
            "執虛器 如執盈 入虛室 如有人",
            "事勿忙 忙多錯 勿畏難 勿輕略",
            "鬥鬧場 絕勿近 邪僻事 絕勿問",
            "將入門 問孰存 將上堂 聲必揚",
            "人問誰 對以名 吾與我 不分明",
            "用人物 須明求 倘不問 即為偷",
            "借人物 及時還 後有急 借不難",
          ],
        },
        {
          chapter: "信",
          paragraphs: [
            "凡出言 信為先 詐與妄 奚可焉",
            "話說多 不如少 惟其是 勿佞巧",
            "奸巧語 穢汙詞 市井氣 切戒之",
            "見未真 勿輕言 知未的 勿輕傳",
            "事非宜 勿輕諾 苟輕諾 進退錯",
            "凡道字 重且舒 勿急疾 勿模糊",
            "彼說長 此說短 不關己 莫閑管",
            "見人善 即思齊 縱去遠 以漸躋",
            "見人惡 即內省 有則改 無加警",
            "唯德學 唯才藝 不如人 當自礪",
            "若衣服 若飲食 不如人 勿生戚",
            "聞過怒 聞譽樂 損友來 益友卻",
            "聞譽恐 聞過欣 直諒士 漸相親",
            "無心非 名為錯 有心非 名為惡",
            "過能改 歸於無 倘掩飾 增一辜",
          ],
        },
        {
          chapter: "泛愛眾",
          paragraphs: [
            "凡是人 皆須愛 天同覆 地同載",
            "行高者 名自高 人所重 非貌高",
            "才大者 望自大 人所服 非言大",
            "己有能 勿自私 人所能 勿輕訾",
            "勿諂富 勿驕貧 勿厭故 勿喜新",
            "人不閑 勿事攪 人不安 勿話擾",
            "人有短 切莫揭 人有私 切莫說",
            "道人善 即是善 人知之 愈思勉",
            "揚人惡 即是惡 疾之甚 禍且作",
            "善相勸 德皆建 過不規 道兩虧",
            "凡取與 貴分曉 與宜多 取宜少",
            "將加人 先問己 己不欲 即速已",
            "恩欲報 怨欲忘 報怨短 報恩長",
            "待婢僕 身貴端 雖貴端 慈而寬",
            "勢服人 心不然 理服人 方無言",
          ],
        },
        {
          chapter: "親仁",
          paragraphs: [
            "同是人 類不齊 流俗眾 仁者希",
            "果仁者 人多畏 言不諱 色不媚",
            "能親仁 無限好 德日進 過日少",
            "不親仁 無限害 小人進 百事壞",
          ],
        },
        {
          chapter: "余力學文",
          paragraphs: [
            "不力行 但學文 長浮華 成何人",
            "但力行 不學文 任己見 昧理真",
            "讀書法 有三到 心眼口 信皆要",
            "方讀此 勿慕彼 此未終 彼勿起",
            "寬為限 緊用功 工夫到 滯塞通",
            "心有疑 隨劄記 就人問 求確義",
            "房室清 牆壁淨 幾案潔 筆硯正",
            "墨磨偏 心不端 字不敬 心先病",
            "列典籍 有定處 讀看畢 還原處",
            "雖有急 卷束齊 有缺壞 就補之",
            "非聖書 屏勿視 蔽聰明 壞心志",
            "勿自暴 勿自棄 聖與賢 可馴致",
          ],
        },
      ],
    },
    {
      sctitle: "朱子家训",
      tctitle: "朱子家訓",
      pytitle: "Zhu Zi Jia Xun",
      entitle: "The Family Instruction of Zhu Zi",
      othertitle: "",
      scauthor: "",
      tcauthor: "朱柏廬",
      enauthor: "",
      school: "General",
      dynasty: "",
      year: "",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      content: [
        {
          chapter: "全文", 
          paragraphs: [
            "黎明即起，灑掃庭除，要內外整潔；",
            "既昏便息，關鎖門戶，必親自檢點。",
            "一粥一飯，當思來處不易；",
            "半絲半縷，恆念物力維艱。",
            "宜未雨而綢繆，毋臨渴而掘井。",
            "自奉必須儉約，宴客切勿流連。",
            "器具質而潔，瓦缶勝金玉；",
            "飲食約而精，園蔬愈珍饈。",
            "勿營華屋，勿謀良田。",
            "三姑六婆，實淫盜之媒；",
            "婢美妾嬌，非閨房之福。",
            "僮僕勿用俊美，妻妾切忌艷裝。",
            "祖宗雖遠，祭祀不可不誠；",
            "子孫雖愚，經書不可不讀。",
            "居身務期質樸，教子要有義方。",
            "勿貪意外之財，勿飲過量之酒。",
            "與肩挑貿易，毋佔便宜；",
            "見貧苦親鄰，須加溫卹。",
            "刻薄成家，理無久享；",
            "倫常乖舛，立見消亡。",
            "兄弟叔侄，需分多潤寡；",
            "長幼內外，宜法肅辭嚴。",
            "聽婦言乖骨肉，豈是丈夫？",
            "重資財薄父母，不成人子。",
            "嫁女擇佳婿，毋索重聘；",
            "娶媳求淑女，勿計厚奩。",
            "見富貴而生諂容者最可恥；",
            "遇貧窮而作驕態者賤莫甚。",
            "居家戒爭訟，訟則終兇；",
            "處世戒多言，言多必失。",
            "勿恃勢力而凌逼孤寡，毋貪口腹而恣殺生禽。",
            "乖僻自是，悔誤必多；",
            "頹惰自甘，家道難成。",
            "狎昵惡少，久必受其累；",
            "屈志老成，急則可相依。",
            "輕聽發言，安知非人之譖訴，當忍耐三思；",
            "因事相爭，焉知非我之不是，需平心暗想。",
            "施惠無念，受恩莫忘。",
            "凡事當留餘地，得意不宜再往。",
            "人有喜慶，不可生嫉妒心；",
            "人有禍患，不可生喜幸心。",
            "善欲人見，不是真善；",
            "惡恐人知，便是大惡。",
            "見色而起淫心，報在妻女；",
            "匿怨而用暗箭，禍延子孫。",
            "家門和順，雖饔飧不繼，亦有餘歡；",
            "國課早完，即囊橐無餘，自得至樂。",
            "讀書志在聖賢，非徒科第；",
            "為官心存君國，豈計身家？",
            "守分安命，順時聽天。",
            "為人若此，庶乎近焉。",
          ],
        },
      ],
    },
    {
      sctitle: "楚辞",
      tctitle: "楚辭",
      pytitle: "Chu Ci",
      entitle: "The Songs of Chu",
      othertitle: "",
      scauthor: "",
      tcauthor: "屈原",
      enauthor: "Qu Yuan",
      school: "School of Minor-Talks",
      dynasty: "春秋戰國",
      year: "",
      description: "",
      summary: "",
      baiduurl: "",
      cnwikiurl: "",
      enwikiurl: "",
      tags: [],
      content: [
        {
          chapter: "离骚",
          title: "离骚",
          author: "屈原",
          paragraphs: [
            "帝高阳之苗裔兮，朕皇考曰伯庸。",
            "摄提贞于孟陬兮，惟庚寅吾以降。",
            "皇览揆余初度兮，肇锡余以嘉名：",
            "名余曰正则兮，字余曰灵均。",
            "纷吾既有此内美兮，又重之以修能。",
            "扈江离与辟芷兮，纫秋兰以为佩。",
            "汨余若将不及兮，恐年岁之不吾与。",
            "朝搴阰之木兰兮，夕揽洲之宿莽。",
            "日月忽其不淹兮，春与秋其代序。",
            "惟草木之零落兮，恐美人之迟暮。",
            "不抚壮而弃秽兮，何不改乎此度?",
            "乘骐骥以驰骋兮，来吾道夫先路！",
            "昔三后之纯粹兮，固众芳之所在。",
            "杂申椒与菌桂兮，岂惟纫夫蕙茝！",
            "彼尧、舜之耿介兮，既遵道而得路。",
            "何桀纣之猖披兮，夫惟捷径以窘步。",
            "惟夫党人之偷乐兮，路幽昧以险隘。",
            "岂余身之殚殃兮，恐皇舆之败绩！",
            "忽奔走以先后兮，及前王之踵武。",
            "荃不查余之中情兮，反信谗而齌怒。",
            "余固知謇謇之为患兮，忍而不能舍也。",
            "指九天以为正兮，夫惟灵修之故也。",
            "曰黄昏以为期兮，羌中道而改路！",
            "初既与余成言兮，后悔遁而有他。",
            "余既不难夫离别兮，伤灵修之数化。",
            "余既滋兰之九畹兮，又树蕙之百亩。",
            "畦留夷与揭车兮，杂杜衡与芳芷。",
            "冀枝叶之峻茂兮，愿俟时乎吾将刈。",
            "虽萎绝其亦何伤兮，哀众芳之芜秽。",
            "众皆竞进以贪婪兮，凭不厌乎求索。",
            "羌内恕己以量人兮，各兴心而嫉妒。",
            "忽驰骛以追逐兮，非余心之所急。",
            "老冉冉其将至兮，恐修名之不立。",
            "朝饮木兰之坠露兮，夕餐秋菊之落英。",
            "苟余情其信姱以练要兮，长顑颔亦何伤。",
            "掔木根以结茞兮，贯薜荔之落蕊。",
            "矫菌桂以纫蕙兮，索胡绳之纚纚。",
            "謇吾法夫前修兮，非世俗之所服。",
            "虽不周于今之人兮，愿依彭咸之遗则。",
            "长太息以掩涕兮，哀民生之多艰。",
            "余虽好修姱以鞿羁兮，謇朝谇而夕替。",
            "既替余以蕙纕兮，又申之以揽茝。",
            "亦余心之所善兮，虽九死其犹未悔。",
            "怨灵修之浩荡兮，终不察夫民心。",
            "众女嫉余之蛾眉兮，谣诼谓余以善淫。",
            "固时俗之工巧兮，偭规矩而改错。",
            "背绳墨以追曲兮，竞周容以为度。",
            "忳郁邑余侘傺兮，吾独穷困乎此时也。",
            "宁溘死以流亡兮，余不忍为此态也。",
            "鸷鸟之不群兮，自前世而固然。",
            "何方圜之能周兮，夫孰异道而相安？",
            "屈心而抑志兮，忍尤而攘诟。",
            "伏清白以死直兮，固前圣之所厚。",
            "悔相道之不察兮，延伫乎吾将反。",
            "回朕车以复路兮，及行迷之未远。",
            "步余马于兰皋兮，驰椒丘且焉止息。",
            "进不入以离尤兮，退将复修吾初服。",
            "制芰荷以为衣兮，集芙蓉以为裳。",
            "不吾知其亦已兮，苟余情其信芳。",
            "高余冠之岌岌兮，长余佩之陆离。",
            "芳与泽其杂糅兮，唯昭质其犹未亏。",
            "忽反顾以游目兮，将往观乎四荒。",
            "佩缤纷其繁饰兮，芳菲菲其弥章。",
            "民生各有所乐兮，余独好修以为常。",
            "虽体解吾犹未变兮，岂余心之可惩。",
            "女嬃之婵媛兮，申申其詈予，曰：",
            "鲧婞直以亡身兮，终然夭乎羽之野。",
            "汝何博謇而好修兮，纷独有此姱节？",
            "薋菉葹以盈室兮，判独离而不服。",
            "众不可户说兮，孰云察余之中情？",
            "世并举而好朋兮，夫何茕独而不予听？",
            "依前圣以节中兮，喟凭心而历兹。",
            "济沅、湘以南征兮，就重华而敶词：",
            "启《九辩》与《九歌》兮，夏康娱以自纵。",
            "不顾难以图后兮，五子用失乎家衖。",
            "羿淫游以佚畋兮，又好射夫封狐。",
            "固乱流其鲜终兮，浞又贪夫厥家。",
            "浇身被服强圉兮，纵欲而不忍。",
            "日康娱而自忘兮，厥首用夫颠陨。",
            "夏桀之常违兮，乃遂焉而逢殃。",
            "后辛之菹醢兮，殷宗用而不长。",
            "汤、禹俨而祗敬兮，周论道而莫差。",
            "举贤才而授能兮，循绳墨而不颇。",
            "皇天无私阿兮，览民德焉错辅。",
            "夫维圣哲以茂行兮，苟得用此下土。",
            "瞻前而顾后兮，相观民之计极。",
            "夫孰非义而可用兮？",
            "孰非善而可服？",
            "阽余身而危死兮，览余初其犹未悔。",
            "不量凿而正枘兮，固前修以菹醢。",
            "曾歔欷余郁邑兮，哀朕时之不当。",
            "揽茹蕙以掩涕兮，沾余襟之浪浪。",
            "跪敷衽以陈辞兮，耿吾既得此中正。",
            "驷玉虬以桀鹥兮，溘埃风余上征。",
            "朝发轫于苍梧兮，夕余至乎县圃。",
            "欲少留此灵琐兮，日忽忽其将暮。",
            "吾令羲和弭节兮，望崦嵫而勿迫。",
            "路漫漫其修远兮，吾将上下而求索。",
            "饮余马于咸池兮，总余辔乎扶桑。",
            "折若木以拂日兮，聊逍遥以相羊。",
            "前望舒使先驱兮，后飞廉使奔属。",
            "鸾皇为余先戒兮，雷师告余以未具。",
            "吾令凤鸟飞腾兮，继之以日夜。",
            "飘风屯其相离兮，帅云霓而来御。",
            "纷总总其离合兮，斑陆离其上下。",
            "吾令帝阍开关兮，倚阊阖而望予。",
            "时暧暧其将罢兮，结幽兰而延伫。",
            "世溷浊而不分兮，好蔽美而嫉妒。",
            "朝吾将济于白水兮，登阆风而绁马。",
            "忽反顾以流涕兮，哀高丘之无女。",
            "溘吾游此春宫兮，折琼枝以继佩。",
            "及荣华之未落兮，相下女之可诒。",
            "吾令丰隆乘云兮，求宓妃之所在。",
            "解佩纕以结言兮，吾令謇修以为理。",
            "纷总总其离合兮，忽纬繣其难迁。",
            "夕归次于穷石兮，朝濯发乎洧盘。",
            "保厥美以骄傲兮，日康娱以淫游。",
            "虽信美而无礼兮，来违弃而改求。",
            "览相观于四极兮，周流乎天余乃下。",
            "望瑶台之偃蹇兮，见有娀之佚女。",
            "吾令鸩为媒兮，鸩告余以不好。",
            "雄鸠之鸣逝兮，余犹恶其佻巧。",
            "心犹豫而狐疑兮，欲自适而不可。",
            "凤皇既受诒兮，恐高辛之先我。",
            "欲远集而无所止兮，聊浮游以逍遥。",
            "及少康之未家兮，留有虞之二姚。",
            "理弱而媒拙兮，恐导言之不固。",
            "世溷浊而嫉贤兮，好蔽美而称恶。",
            "闺中既以邃远兮，哲王又不寤。",
            "怀朕情而不发兮，余焉能忍而与此终古？",
            "索琼茅以筳篿兮，命灵氛为余占之。",
            "曰：两美其必合兮，孰信修而慕之？",
            "思九州之博大兮，岂惟是其有女？」",
            "曰：勉远逝而无狐疑兮，孰求美而释女？",
            "何所独无芳草兮，尔何怀乎故宇？」",
            "世幽昧以昡曜兮，孰云察余之善恶？",
            "民好恶其不同兮，惟此党人其独异！",
            "户服艾以盈要兮，谓幽兰其不可佩。",
            "览察草木其犹未得兮，岂珵美之能当？",
            "苏粪壤以充祎兮，谓申椒其不芳。",
            "欲从灵氛之吉占兮，心犹豫而狐疑。",
            "巫咸将夕降兮，怀椒糈而要之。",
            "百神翳其备降兮，九疑缤其并迎。",
            "皇剡剡其扬灵兮，告余以吉故。",
            "曰：勉升降以上下兮，求矩矱之所同。",
            "汤、禹俨而求合兮，挚、咎繇而能调。",
            "苟中情其好修兮，又何必用夫行媒？",
            "说操筑于傅岩兮，武丁用而不疑。",
            "吕望之鼓刀兮，遭周文而得举。",
            "宁戚之讴歌兮，齐桓闻以该辅。",
            "及年岁之未晏兮，时亦犹其未央。",
            "恐鹈鴃之先鸣兮，使夫百草为之不芳。",
            "何琼佩之偃蹇兮，众薆然而蔽之。",
            "惟此党人之不谅兮，恐嫉妒而折之。",
            "时缤纷其变易兮，又何可以淹留？",
            "兰芷变而不芳兮，荃蕙化而为茅。",
            "何昔日之芳草兮，今直为此萧艾也？",
            "岂其有他故兮，莫好修之害也！",
            "余以兰为可恃兮，羌无实而容长。",
            "委厥美以从俗兮，苟得列乎众芳。",
            "椒专佞以慢慆兮，樧又欲充夫佩帏。",
            "既干进而务入兮，又何芳之能祗？",
            "固时俗之流从兮，又孰能无变化？",
            "览椒兰其若兹兮，又况揭车与江离？",
            "惟兹佩之可贵兮，委厥美而历兹。",
            "芳菲菲而难亏兮，芬至今犹未沬。",
            "和调度以自娱兮，聊浮游而求女。",
            "及余饰之方壮兮，周流观乎上下。",
            "灵氛既告余以吉占兮，历吉日乎吾将行。",
            "折琼枝以为羞兮，精琼爢以为粻。",
            "为余驾飞龙兮，杂瑶象以为车。",
            "何离心之可同兮？",
            "吾将远逝以自疏。",
            "邅吾道夫昆仑兮，路修远以周流。",
            "扬云霓之晻蔼兮，鸣玉鸾之啾啾。",
            "朝发轫于天津兮，夕余至乎西极。",
            "凤皇翼其承旗兮，高翱翔之翼翼。",
            "忽吾行此流沙兮，遵赤水而容与。",
            "麾蛟龙使梁津兮，诏西皇使涉予。",
            "路修远以多艰兮，腾众车使径待。",
            "路不周以左转兮，指西海以为期。",
            "屯余车其千乘兮，齐玉轪而并驰。",
            "驾八龙之婉婉兮，载云旗之委蛇。",
            "抑志而弭节兮，神高驰之邈邈。",
            "奏《九歌》而舞《韶》兮，聊假日以偷乐。",
            "陟升皇之赫戏兮，忽临睨夫旧乡。",
            "仆夫悲余马怀兮，蜷局顾而不行。",
            "乱曰：已矣哉！国无人莫我知兮，又何怀乎故都！",
            "既莫足与为美政兮，吾将从彭咸之所居！",
          ],
        },
        {
          chapter: "九歌",
          title: "东皇太一",
          author: "屈原",
          paragraphs: [
            "吉日兮辰良，穆将愉兮上皇",
            "抚长剑兮玉珥，璆锵鸣兮琳琅",
            "瑶席兮玉瑱，盍将把兮琼芳",
            "蕙肴蒸兮兰藉，奠桂酒兮椒浆",
            "扬枹兮拊鼓，疏缓节兮安歌",
            "陈竽瑟兮浩倡",
            "灵偃蹇兮姣服，芳菲菲兮满堂",
            "五音纷兮繁会，君欣欣兮乐康。",
          ],
        },
        {
          chapter: "九歌",
          title: "湘君",
          author: "屈原",
          paragraphs: [
            "君不行兮夷犹，蹇谁留兮中洲",
            "美要眇兮宜修，沛吾乘兮桂舟",
            "令沅湘兮无波，使江水兮安流",
            "望夫君兮未来，吹参差兮谁思",
            "驾飞龙兮北征，邅吾道兮洞庭",
            "薜荔柏兮蕙绸，荪桡兮兰旌",
            "望涔阳兮极浦，横大江兮扬灵",
            "扬灵兮未极，女婵媛兮为余太息",
            "横流涕兮潺湲，隐思君兮陫侧",
            "桂棹兮兰枻，斵冰兮积雪",
            "采薜荔兮水中，搴芙蓉兮木末",
            "心不同兮媒劳，恩不甚兮轻绝",
            "石濑兮浅浅，飞龙兮翩翩",
            "交不忠兮怨长，期不信兮告余以不闲",
            "朝骋骛兮江皋，夕弭节兮北渚",
            "鸟次兮屋上，水周兮堂下",
            "捐余玦兮江中，遗余佩兮醴浦",
            "采芳洲兮杜若，将以遗兮下女",
            "时不可兮再得，聊逍遥兮容与。",
          ],
        },
        {
          chapter: "九歌",
          title: "湘夫人",
          author: "屈原",
          paragraphs: [
            "帝子降兮北渚，目眇眇兮愁予",
            "袅袅兮秋风，洞庭波兮木叶下",
            "登白薠兮骋望，与佳期兮夕张",
            "鸟何萃兮苹中，罾何为兮木上？",
            "沅有茝兮醴有兰，思公子兮未敢言",
            "荒忽兮远望，观流水兮潺湲",
            "麋何食兮庭中，蛟何为兮水裔",
            "朝驰余马兮江皋，夕济兮西澨",
            "闻佳人兮召余，将腾驾兮偕逝",
            "筑室兮水中，葺之兮荷盖",
            "荪壁兮紫坛，播芳椒兮成堂",
            "桂栋兮兰橑，辛夷楣兮药房",
            "罔薜荔兮为帷，擗蕙櫋兮既张",
            "白玉兮为镇，疏石兰兮为芳",
            "芷葺兮荷屋，缭之兮杜衡",
            "合百草兮实庭，建芳馨兮庑门",
            "九嶷缤兮并迎，灵之来兮如云",
            "捐余袂兮江中，遗余褋兮醴浦",
            "搴汀洲兮杜若，将以遗兮远者",
            "时不可兮骤得，聊逍遥兮容与！",
          ],
        },
      ],
    },
  ];
  