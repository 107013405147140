export const people = [
    {
        id: 1, 
        person_scname: "孔子", 
        person_tcname: "孔子", 
        person_enname: "Confucius", 
        person_altname: "", 
        person_school: "Confucianism", 
        person_image: "孔", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 2, 
        person_scname: "老子", 
        person_tcname: "老子", 
        person_enname: "Laozi", 
        person_altname: "", 
        person_school: "Taoism", 
        person_image: "老", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 3, 
        person_scname: "庄子", 
        person_tcname: "莊子", 
        person_enname: "Zhuangzi", 
        person_altname: "", 
        person_school: "Taoism", 
        person_image: "莊", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 4, 
        person_scname: "孟子", 
        person_tcname: "孟子", 
        person_enname: "Mengzi", 
        person_altname: "", 
        person_school: "Confucianism", 
        person_image: "孟", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 5, 
        person_scname: "荀子", 
        person_tcname: "荀子", 
        person_enname: "Xunzi", 
        person_altname: "", 
        person_school: "", 
        person_image: "荀", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 6, 
        person_scname: "墨子", 
        person_tcname: "墨子", 
        person_enname: "Mozi", 
        person_altname: "", 
        person_school: "Mohism", 
        person_image: "墨", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 7, 
        person_scname: "列子", 
        person_tcname: "列子", 
        person_enname: "Liezi", 
        person_altname: "", 
        person_school: "", 
        person_image: "列", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 8, 
        person_scname: "孙子", 
        person_tcname: "孫子", 
        person_enname: "Sunzi", 
        person_altname: "Sun Tzu", 
        person_school: "Militarism", 
        person_image: "孫", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 9, 
        person_scname: "申子", 
        person_tcname: "申子", 
        person_enname: "Shenzi", 
        person_altname: "", 
        person_school: "", 
        person_image: "申", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 10, 
        person_scname: "管子", 
        person_tcname: "管子", 
        person_enname: "Guanzi", 
        person_altname: "", 
        person_school: "", 
        person_image: "管", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 11, 
        person_scname: "韩非子", 
        person_tcname: "韓非子", 
        person_enname: "Han Feizi", 
        person_altname: "", 
        person_school: "", 
        person_image: "韓", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
        {
        id: 12, 
        person_scname: "鬼谷子", 
        person_tcname: "鬼穀子", 
        person_enname: "Guiguzi", 
        person_altname: "", 
        person_school: "", 
        person_image: "鬼穀", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 13, 
        person_scname: "告子", 
        person_tcname: "告子", 
        person_enname: "Gaozi", 
        person_altname: "", 
        person_school: "", 
        person_image: "告", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 14, 
        person_scname: "曾子", 
        person_tcname: "曾子", 
        person_enname: "Zengzi", 
        person_altname: "", 
        person_school: "", 
        person_image: "曾", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 15, 
        person_scname: "扁鹊", 
        person_tcname: "扁鵲", 
        person_enname: "Bian Que", 
        person_altname: "", 
        person_school: "", 
        person_image: "扁鵲", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 16, 
        person_scname: "吕不韦", 
        person_tcname: "呂不韋", 
        person_enname: "Lu Buwei", 
        person_altname: "", 
        person_school: "", 
        person_image: "呂", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
    {
        id: 17, 
        person_scname: "屈原", 
        person_tcname: "屈原", 
        person_enname: "Qu Yuan", 
        person_altname: "", 
        person_school: "School of Minor-Talks", 
        person_image: "屈原", 
        person_dynasty: "",  
        person_birth: "", 
        person_death: "", 
        person_birthplace: "", 
        person_influence: "",
        person_background: "", 
        person_thoughts: "", 
    }, 
]