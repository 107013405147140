import React from "react";
import { useParams } from "react-router-dom";
import { texts } from "../data/TextData";
import "../css/Page.css";

function TextPage() {
  const { titleEN } = useParams();

  const textResult = texts.filter(data => data.entitle.toLowerCase() === titleEN.replaceAll("_", " ")).map((text, textIndex) => {
    // Check if content is available and is an array
    const hasContent = text.content && Array.isArray(text.content) && text.content.length > 0;

    const tableOfContents = hasContent ? (
      <ul className="table-of-contents">
        <span className="text-index">目錄 Table of Content</span>
        {text.content.map((content, idx) => (
          <li key={idx}>
            <a href={`#chapter-${idx}`}>{content.chapter}</a>
          </li>
        ))}
      </ul>
    ) : null;

    return (
      <div className="text-result" key={textIndex}>
        <div className="text-content">
          <h2 className="text-header">{text.tctitle}</h2>
          {/* Render the Table of Contents */}
          <div className="table-of-content">
            {tableOfContents}
          </div>
          {hasContent ? (
            text.content.map((content, contentIndex) => {
              const { chapter, title, author, paragraphs } = content;
              return (
                <div className="text-body" id={`chapter-${contentIndex}`} key={contentIndex}>
                  <button className="btn btn-light content-btn" onClick={() => window.scrollTo(0, 0)}>返回 Back to Top</button>
                  <h3 className="text-subheader">{chapter}</h3>
                  <h4>{title}</h4>
                  <p>{author}</p>
                  <hr />
                  <div className="text-list">
                    {paragraphs && paragraphs.map((line, lineIndex) => (
                      <span className="text-line" key={lineIndex}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
              );
            })
          ) : (
            <p>Coming Soon...</p> // Render this when there is no content
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="page-wrapper">
      <div className="container">
        {textResult.length > 0 ? textResult : "Text Not Found"}
      </div>
    </div>
  );
}

export default TextPage;
