import React from "react";
import { FaInstagram, FaXTwitter, FaDiscord } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';


function Footer() {

    const year = new Date().getFullYear();

    return (
        <footer>
            <div className="social-media">
                <a href="https://www.instagram.com/100schools.io/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="social-icon" />
                </a>
                <a href="https://twitter.com/100schools_" target="_blank" rel="noopener noreferrer">
                    <FaXTwitter className="social-icon" />
                </a>
                <Link to="/contact">
                    <MdEmail className="social-icon" />
                </Link>
            </div>
            <button className="btn btn-light btn-coffee">
                <a href="https://www.buymeacoffee.com/hanfuworld" className="bmc-link" target="_blank" rel="noopener noreferrer" >
                   ❤️ 请我们喝咖啡 Buy us coffee :) ☕️ ❤️
                </a>           
            </button>
            <p>{year} @ 百家 100 Schools</p>
        </footer>
    )
}

export default Footer; 