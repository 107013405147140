import React from "react";
import { Link } from 'react-router-dom';
import { texts } from "../data/TextData";
import "../css/Text.css";
// import { colors } from "@material-ui/core";
import TextPage from '../pages/TextPage'; 

function Texts() {
    // console.log(texts);

    const getColor = (school) => {
       switch(school) {
        case "Confucianism": return '#18BFFF'; 
        case "Taoism": return '#FFD66E'; 
        case "Legalism": return '#F82B60'; 
        case "Militarism": return '#CDB0FF'; 
        case "Mohism": return '#666666'; 
        case "Logicism": return '#FFA981'; 
        case "School of Yin-Yang": return '#CCCCCC'; 
        case "School of Diplomacy": return '#20D9D2'; 
        case "Agriculturalism": return '#93E088'; 
        case "Yangism": return '#FF08C2';
        case "Syncretism": return '#2D7FF9';
        case "School of Medical Skills": return '#FCB400'; 
        default: return '#CCCCCC'; 
       }
    }

    const textList = texts.map((text, i) => {

      let titleEN = text.entitle.replace(/\s/g, '_').toLowerCase(); 

      return (
        <li className="text-item" key={i + 1}>
          
          <Link to={{pathname: `/texts/${titleEN}`, state: {titleEN} }} className="text-link">
          <div
            className="text-card"
            style={{ backgroundColor: getColor(text.school) }}
          >
            <div className="text-title-container">
              <div className="text-title-outline">
                <span className="text-title"> {text.tctitle}</span>
              </div>
            </div>
            <div className="text-card-edge">
              <span className="text-card-line"></span>
              <span className="text-card-line"></span>
              <span className="text-card-line"></span>
              <span className="text-card-line"></span>
            </div>
          </div>
          <div className="text-info">
            <span className="text-entitle">{text.entitle}</span>
          </div>
          </Link>
        </li>
      );
    });

    return (
      <div className="page-wrapper">
        <h2 className="page-header">中華古籍</h2>
        <h3 className="page-subheader">Classical Chinese Texts 📚</h3>
        <div className="items-list">{textList}</div>
      </div>
    ); 
  
}

export default Texts; 