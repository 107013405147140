import React from "react";
import { Link, useParams } from "react-router-dom";
import { schools } from "../data/SchoolData";
import { texts } from "../data/TextData";
import "../css/Page.css";

function SchoolPage() {

  const { schoolName } = useParams();
  // console.log(schoolName);
  let schoolInfo = schools.filter((data) => {
    if (schoolName === data.school_enname) {
      return data;
    }
    return null;
  });

  const schoolTCName = schoolInfo.length > 0 ? schoolInfo[0].school_tcname : "Coming Soon...";

  schoolInfo = schoolInfo.map((school, index) => {
    return (
      <div className="table-container">
      <table className="table" key={index}>
      <tbody>
          <tr>
              <th scope="row">學派 / School:</th>
              <td>{school.school_tcname} / {school.school_enname}</td>
          </tr>
          <tr>
              <th scope="row">創始人 / Founder(s):</th>
              <td>{school.school_founder || "Coming Soon..."}</td>
          </tr>
          <tr>
              <th scope="row">教學 / Teachings:</th>
              <td colSpan="2">{school.school_values || "Coming Soon..."}</td>
          </tr>
      </tbody>
  </table>
      </div>

    );
  });

  let schoolTexts = texts.filter((data) => {
    if (schoolName === data.school) {
      return data;
    }
    return null;
  });

  schoolTexts = schoolTexts.map((text, i) => {
    const getColor = () => {
      let color;
      if (text.school === "Confucianism") {
        color = "#18BFFF";
      } else if (text.school === "Taoism") {
        color = "#FFD66E";
      } else if (text.school === "Legalism") {
        color = "#F82B60";
      } else if (text.school === "School of the Military") {
        // color = '#8B46FF'
        color = "#CDB0FF";
      } else if (text.school === "Mohism") {
        color = "#666666";
      } else if (text.school === "School of Names") {
        // color = '#FF6F2C'
        color = "#FFA981";
      } else if (text.school === "School of Yin-Yang") {
        color = "#CCCCCC";
      } else if (text.school === "School of Diplomacy") {
        color = "#20D9D2";
      } else if (text.school === "Agriculturalism") {
        color = "#93E088";
      } else if (text.school === "Yangism") {
        color = "#FF08C2";
      } else if (text.school === "Syncretism") {
        color = "#2D7FF9";
      } else if (text.school === "School of Medical Skills") {
        color = "#FCB400";
      } else {
        color = "#CCCCCC";
      }
      return color;
    };

    let titleTC = text.tctitle;
    let titleEN = text.entitle.replace(/\s/g, "_").toLowerCase();

    // console.log(schoolTexts.length)
    return (
      <li className="text-item" key={i + 1}>
        <Link
          // to={{ pathname: `/texts/${titleTC}`, state: { titleTC } }}
          to={{ pathname: `/texts/${titleEN}`, state: { titleEN } }}
          className="text-link"
        >
          <div
            className="text-card"
            style={{
              backgroundColor:
                // text.school === "Confucianism" ? "#18BFFF" : "blue",
                getColor(),
            }}
          >
            <div className="text-title-container">
              <div className="text-title-outline">
                <span className="text-title"> {text.tctitle}</span>
              </div>
            </div>
            <div className="text-card-edge">
              <span className="text-card-line"></span>
              <span className="text-card-line"></span>
              <span className="text-card-line"></span>
              <span className="text-card-line"></span>
            </div>
          </div>
          <div className="text-info">
            <span className="text-entitle">{text.entitle}</span>
          </div>
        </Link>
      </li>
    );
  });

  return (
    <div className="page-wrapper">
        <h2 className="page-header">{schoolTCName}</h2>
        <h3 className="page-subheader">{schoolName}</h3>
        <div className="container">
      <div className="schoolpage-info">{schoolInfo}</div>
      <div className="schoolpage-texts">
        <h2 className="page-header">{schoolTCName}著作</h2>
        <h3 className="page-subheader">{schoolName} Texts</h3>
        {schoolTexts.length ? (
          <div>
            <ul className="items-list">{schoolTexts}</ul>
          </div>
        ) : (
          <div className="schoolpage-message">Coming Soon...</div>
        )}
      </div>
      </div>
    </div>
  );
}

export default SchoolPage;
