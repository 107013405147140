import React from "react";
import "../css/Page.css";

function About() {
  return (
    <div className="page-wrapper">
      <h2 className="page-header">關於我們</h2>
      <h3 className="page-subheader">About Us 🙌🏻</h3>
      <div className="container">
        {/* <div className="quote-box">
          <p className="intro-text"><i>“为天地立心，为生民立命，为往圣继绝学，为万世开太平”  -（北宋[960-1127]）张载[1020-1077]</i></p>
          <p className="intro-text"><i>“為天地立心，為生民立命，為往聖繼絕學，為萬世開太平”  -（北宋[960-1127]）張載[1020-1077]</i></p>
          <p className="intro-text"><i>Literal Translation: Establish a heart and mind for the heaven and earth, establish a purpose and destiny for the living people, carry on the unique and profound teachings of the past saints, and create great peace for the world for all generations to come. - Zhang Zai [1020-1077] (Northern Song Dynasty [960-1127]) </i></p>
          <p className="intro-text"><i>Figurative Translation: "Let us set our hearts for the welfare of the world, dedicate our lives to the good of all people, carry forward the legacy of ancient sages, and pave the way for eternal peace and harmony." - Zhang Zai [1020-1077] (Northern Song Dynasty[960-1127])</i></p>
        </div> */}
        <div className="text-box">
          <p className="intro-text">
          百家學說為開花於公元前600年至公元前221年的中國古代哲學及學術思想流派。此時代，經歷春秋（公元前770年至公元前481年）及戰國（公元前476年至公元前221年）時期，被譽為中華哲學的黃金時代。
          </p>
          <p className="intro-text">
            100 Schools of Thought are Chinese philosophies and schools of thought that blossomed from the 600 B.C. to 221 B.C. in ancient China. This era, lasted throughout the Spring and Autumn period (770 B.C. - 481 B.C.) and the Warring States period (476 B.C. - 221 B.C.), is revered as the Golden Age of Chinese philosophy.
          </p>
          <p className="intro-text">
          孔子、老子、莊子等聖賢與智者及其思想在這段時期蓬勃發展，永遠地塑造與啟蒙了中華文化及世界文明。在中國歷史長河中，無數哲學家與思想家對這些哲理和教誨進行了詮釋與拓展，豐富了燦爛的文化遺產，創造了多樣的哲學思想。
          </p>
          <p className="intro-text">
            Saints and Sages like Kongzi (Confucius), Laozi (Lao Tzu), and Zhuangzi and their thoughts flourished in this period, forever shaping and enlightening Chinese culture and world civilization. Throughout Chinese history, countless philosophers and thinkers have interpreted and expanded these philosophies and teachings, building rich legacies and creating diverse new thoughts.
          </p>
          <p className="intro-text">
          這些中華傳統哲學與思想，數千年來對東西方文化產生了重大與深遠的影響。其永恆的智慧至今仍然與時俱進，繼續在生活的各個方面提供寶貴的價值與指引。💡
          </p>
          <p className="intro-text">
            These traditional Chinese philosophies and thoughts have significantly influenced both Eastern and Western cultures for millennia. Their timeless wisdom continues to resonate, offering valuable insights and guidance in every facet of life today. 💡
          </p>
          <p className="intro-text">
          然而，古代中文典籍多用古文書寫，尋找、閱讀與理解多有難度。無論您是學習中文、對中國哲學思想有興趣，或是希望拓展對世界的認識與理解，我們期望使這些古籍與文獻更易於接觸。
          </p>
          <p className="intro-text">
          However, ancient Chinese texts are written in Classical Chinese and usually hard to find, read, and understand. Whether you are learning Chinese, interested in Chinese philosophies and thoughts, or want to expand your thoughts and understandings of the world, we want to make these ancient Chinese texts and teachings accessible to you.          </p>
          <p className="intro-text">
          我們致力於讓人們更容易地發現、閱讀、理解這些中華古籍與哲學思想，以此增進理解，架設溝通的橋樑，促進內外和平，共創更美好的世界。❤️
          </p>
          <p className="intro-text">
          We want to make it easier and fun for people to find, read, and understand Classical Chinese texts and philosophies, and make the world a better place by increasing understanding, building bridges, and promoting both outer and inner peace. ❤️
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
