export const schools = [
    {
        id: 1, 
        school_scname: "儒家", 
        school_tcname: "儒家", 
        school_enname: "Confucianism", 
        school_image: "儒", 
        school_founder: "孔子 / Kong Zi (Confucius)", 
        school_values: "Ren (Benevolence), Yi (Rightousness), Li (Ritual Norms), Zhi (Wisdom, Ability), Family, Social Harmony, Humanism, Virtue, Work Ethic, Order, Scholarship", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 2, 
        school_scname: "道家", 
        school_tcname: "道家", 
        school_enname: "Taoism",
        school_image: "道", 
        school_founder: "老子 / Lao Zi (Laozi)", 
        school_values: "Wuwei (action without intention), Naturalness, Simplicity, Spontaneity, Compassion, Frugality, Humility, Self-cultivation, Ecstasy, Longevity, Immortality", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 3, 
        school_scname: "法家", 
        school_tcname: "法家", 
        school_enname: "Legalism", 
        school_image: "法", 
        school_founder: "商鞅 / Shang Yang, 李悝 / Li Kui",
        school_values: "Power, Tactics, Rule, Merit, System (of punishment and rewards), Reform, Realism, Regulation",  
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 4, 
        school_scname: "兵家", 
        school_tcname: "兵家", 
        school_enname: "School of the Military",
        school_image: "兵", 
        school_founder: "姜子牙 / Jiang Zi Ya, 孙子 / Sun Zi (Sun Tse)", 
        school_values: "Strategy, Warfare, Weaponry, Leadership, Competition, Management", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 5, 
        school_scname: "墨家", 
        school_tcname: "墨家", 
        school_enname: "Mohism", 
        school_image: "墨", 
        school_founder: "墨子 / Mo Zi (Mozi)", 
        school_values: "Universal Love, Ethics, Logic, Rational Thought, Science, Altruism, Austerity, Utilitarianism, Meritocracy, Oraganization", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 6, 
        school_scname: "名家", 
        school_tcname: "名家", 
        school_enname: "School of Names", 
        school_image: "名", 
        school_founder: "公孙龙 / Gongsun Long", 
        school_values: "Form, Definition, Logic, Truth, Discernment, Perception & Reality, Observation, Investigation, Reasoning, Understanding", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 7, 
        school_scname: "阴阳家", 
        school_tcname: "陰陽家", 
        school_enname: "School of Yin-Yang", 
        school_image: "阴阳", 
        school_founder: "鄒衍/Zou Yan", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 8, 
        school_scname: "纵横家", 
        school_tcname: "縱橫家", 
        school_enname: "School of Diplomacy", 
        school_image: "纵横", 
        school_founder: "鬼谷子 / Gui Gu Zi, 张仪/Zhang Yi, 苏秦 / Su Qin", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 9, 
        school_scname: "农家", 
        school_tcname: "農家", 
        school_enname: "Agriculturalism",
        school_image: "农", 
        school_founder: "许行 / Xú Xíng", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 10, 
        school_scname: "杨朱学派", 
        school_tcname: "楊朱學派", 
        school_enname: "Yangism", 
        school_image: "杨朱", 
        school_founder: "杨朱 / Yang Zhu", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 11, 
        school_scname: "杂家", 
        school_tcname: "雜家", 
        school_enname: "Syncretism",  
        school_image: "雜", 
        school_founder: "吕不韦 / Lu Buwei", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 12, 
        school_scname: "方技家", 
        school_tcname: "方技家", 
        school_enname: "School of Medical Skills", 
        school_image: "方技", 
        school_founder: "扁鹊 / Bian Que, 岐伯 / Qi Bo", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 13, 
        school_scname: "数术", 
        school_tcname: "數術", 
        school_enname: "School of Operation Mathematics", 
        school_altname: "陆王心学", 
        school_image: "数术", 
        school_founder: "王陽明", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 14, 
        school_scname: "心学", 
        school_tcname: "心學", 
        school_enname: "School of the Mind, Neo-Confucianism", 
        school_altname: "陆王心学", 
        school_image: "心", 
        school_founder: "王陽明", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
    {
        id: 15, 
        school_scname: "理学", 
        school_tcname: "理學", 
        school_enname: "School of Rationalism, Neo-Confucianism", 
        school_altname: "程朱理學", 
        school_image: "理", 
        school_founder: "程颐、朱熹", 
        school_values: "", 
        school_teaching: "", 
        school_literature: "",
        school_funfact: "", 
    }, 
]