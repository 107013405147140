import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../css/AppNavbar.css";

export default function Navbar() {

  const navbarRef = useRef(null);
  const collapseRef = useRef(null); 

  const adjustBodyPadding = () => {
    if (navbarRef.current) {
      const navbarHeight = navbarRef.current.clientHeight; 
      document.body.style.paddingTop = `${navbarHeight}px`; 
    }
  };

  useEffect(() => {
    // Adjust padding initially
    adjustBodyPadding(); 

    // Event listeners for window resize and Bootstrap collapse events
    window.addEventListener('resize', adjustBodyPadding);

    // Attach collapse event listeners only if collapseRef.current is not null
    if (collapseRef.current) {
      collapseRef.current.addEventListener('shown.bs.collapse', adjustBodyPadding);
      collapseRef.current.addEventListener('hidden.bs.collapse', adjustBodyPadding);
    }

    // Cleanup listeners
    return () => {
      window.removeEventListener('resize', adjustBodyPadding);

    if (collapseRef.current) {
      collapseRef.current.removeEventListener('shown.bs.collapse', adjustBodyPadding);
      collapseRef.current.removeEventListener('hidden.bs.collapse', adjustBodyPadding);
    }
    };
  }, []);

  return (
    <nav ref={navbarRef} className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <div className="navbar-header">
          <div className="navbar-logo-container">
            <img className="navbar-icon" src="/images/100schools_logo.png" alt="100schools-logo" />
            <Link className="navbar-name navbar-item-link" to="/">百家 100 Schools</Link>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          </div>
        <div ref={collapseRef} className="collapse navbar-collapse me-auto" id="navbarSupportedContent">
          <ul className="navbar-nav navbar-left me-auto">
            <li className="nav-item">
              <Link to="/schools" className="navbar-item-link">
                <span>哲學 Schools</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/texts" className="navbar-item-link">
                <span>古籍 Texts</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/people" className="navbar-item-link">
                <span>聖賢 People</span>
              </Link>
            </li>
          </ul>

          <ul className="navbar-nav navbar-right ms-auto">
            <li className="nav-item">
              <Link to="/about" className="navbar-item-link">
                <span>關於 About</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="navbar-item-link">
                <span>聯繫 Contact</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
  
    </nav>

  );
}
