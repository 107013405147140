import React from "react";
import { Link } from "react-router-dom";
import { people } from "../data/PeopleData";
import "../css/List.css";

function People() {

    const peopleList = people.map((person, i) => {
      let personName = person.person_enname.replace(/\s/g, "_").toLowerCase();

      return (
        <li className="list-item" key={i}>
          <Link
            to={{ pathname: `/people/${personName}`, state: { personName } }}
            className="item-link"
          >
            <div className="item-card">
              <div className="item-image-container">
              <span className="item-image-text">{person.person_image}</span>
                {/* <img
                    // src={person.imageURL} // Assuming each person object has an imageURL property
                  className="item-image"
                  alt={`${person.person_enname}'s profile`}
                /> */}
              </div>
              <div className="item-info">
                <span className="item-title">
                  {person.person_tcname} / {person.person_enname}
                </span>
              </div>
            </div>
          </Link>
        </li>
      );
    });

    return (
      <div className="page-wrapper">
        <h2 className="page-header">中華哲學和思想家</h2>
        <h3 className="page-subheader">Chinese Philosophers & Thinkers 👨🏻‍🦳</h3>
        <div className="items-list">{peopleList}</div>
      </div>
    );
};

export default People; 
