import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { people } from "../data/PeopleData";
import { texts } from "../data/TextData";
import "../css/Page.css";

function PersonPage() {

    const { personName } = useParams();
    // console.log(personName); 

    const capitalizeWords = (str) => {
        return str
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')
    };

    const formattedPersonName = capitalizeWords(personName);
    // console.log(formattedPersonName)

    let personInfo = people.filter((data) => formattedPersonName === data.person_enname);
    // console.log(personInfo)

    const personTCName = personInfo.length > 0 ? personInfo[0].person_tcname : "Coming Soon...";

    personInfo = personInfo.map((person) => {
        return (
            <table className="table" key={person.id}>
                <tbody>
                    <tr>
                        <th scope="row">姓名 / Name:</th>
                        <td>{person.person_tcname} / {person.person_enname}</td>
                    </tr>
                    <tr>
                        <th scope="row">朝代 / Dynasty:</th>
                        <td>{person.person_dynasty || "Coming soon..."}</td>
                    </tr>
                    <tr>
                        <th scope="row">出生 / Birth:</th>
                        <td colSpan="2">{person.person_death || "Coming soon..."}</td>
                    </tr>
                    <tr>
                        <th scope="row">去世 / Death:</th>
                        <td colSpan="2">{person.person_death || "Coming soon..."}</td>
                    </tr>
                    <tr>
                        <th scope="row">家鄉 / Hometown:</th>
                        <td colSpan="2">{person.person_birthplace || "Coming soon..."}</td>
                    </tr>
                    <tr>
                        <th scope="row">影響 / Influence:</th>
                        <td colSpan="2">{person.person_influence || "Coming soon..."}</td>
                    </tr>
                    <tr>
                        <th scope="row">背景 / Background:</th>
                        <td colSpan="2">{person.person_background || "Coming soon..."}</td>
                    </tr>
                    <tr>
                        <th scope="row">思想 / Thoughts</th>
                        <td colSpan="2">{person.person_thoughts || "Coming soon..."}</td>
                    </tr>
                </tbody>
            </table>
        )
    });

    let personTexts = texts.filter((data) => formattedPersonName === data.enauthor);
    // console.log(personTexts);

    personTexts = personTexts.map((text, textIndex) => {
        const getColor = () => {
            let color;
            if (text.school === "Confucianism") {
                color = "#18BFFF";
            } else if (text.school === "Taoism") {
                color = "#FFD66E";
            } else if (text.school === "Legalism") {
                color = "#F82B60";
            } else if (text.school === "School of the Military") {
                // color = '#8B46FF'
                color = "#CDB0FF";
            } else if (text.school === "Mohism") {
                color = "#666666";
            } else if (text.school === "School of Names") {
                // color = '#FF6F2C'
                color = "#FFA981";
            } else if (text.school === "School of Yin-Yang") {
                color = "#CCCCCC";
            } else if (text.school === "School of Diplomacy") {
                color = "#20D9D2";
            } else if (text.school === "Agriculturalism") {
                color = "#93E088";
            } else if (text.school === "Yangism") {
                color = "#FF08C2";
            } else if (text.school === "Syncretism") {
                color = "#2D7FF9";
            } else if (text.school === "School of Medical Skills") {
                color = "#FCB400";
            } else {
                color = "#CCCCCC";
            }
            return color;
        };
        let titleTC = text.tctitle;
        let titleEN = text.entitle.replace(/\s/g, "_").toLowerCase();

        // console.log(personTexts.length)
        return (
            <li className="text-item" key={textIndex}>
                <Link
                    // to={{ pathname: `/texts/${titleTC}`, state: { titleTC } }}
                    to={{ pathname: `/texts/${titleEN}`, state: { titleEN } }}
                    className="text-link"
                >
                    <div
                        className="text-card"
                        style={{
                            backgroundColor:
                                // text.school === "Confucianism" ? "#18BFFF" : "blue",
                                getColor(),
                        }}
                    >
                        <div className="text-title-container">
                            <div className="text-title-outline">
                                <span className="text-title"> {text.tctitle}</span>
                            </div>
                        </div>
                        <div className="text-card-edge">
                            <span className="text-card-line"></span>
                            <span className="text-card-line"></span>
                            <span className="text-card-line"></span>
                            <span className="text-card-line"></span>
                        </div>
                    </div>
                    <div className="text-info">
                        <span className="text-entitle">{text.entitle}</span>
                    </div>
                </Link>
            </li>
        );
    });

    return (
        <div className="personpage">
            <div className="page-container">
                <h2 className="page-header">{personTCName}</h2>
                <h3 className="page-subheader">{formattedPersonName}</h3>
            </div>
            <div className="personpage-info">
                {personInfo.length > 0 ? personInfo : <p>Coming Soon...</p>}
            </div>
            <div className="page-container">
                <h2 className="page-header">{personTCName}著作</h2>
                <h3 className="page-subheader">{formattedPersonName} Texts</h3>
                {personTexts.length ? (
                    <div>
                        <ul className="items-list">{personTexts}</ul>
                    </div>
                ) : (
                    <div className="personpage-message">Coming Soon...</div>
                )}
            </div>
        </div>
    )


}

export default PersonPage; 