import React from "react";
import { schools } from "../data/SchoolData";
import { Link } from 'react-router-dom'; 
import SchoolPage from '../pages/SchoolPage'; 
import "../css/List.css";

function Schools() {

    const schoolList = schools.map((school, i) => {
      let schoolName = school.school_enname; 
      return (
        <li className="list-item" key={i + 1}>
          <Link to={{ pathname: `/schools/${schoolName}`, state: {schoolName}}} className="item-link">
          <div className="item-card">
            <div className="item-image-container">
              <span className="item-image-text">{school.school_image}</span>
            </div>
            <div className="item-info">
              <span className="item-title">
                {school.school_tcname} / {school.school_enname}
              </span>
            </div>
          </div>
          </Link>
        </li>
      );
    });

    return (
      <div className="page-wrapper">
        <h2 className="page-header">中華哲學思想</h2>
        <h3 className="page-subheader">Chinese Schools of Philosophy ☯️</h3>
        <div className="items-list">{schoolList}</div>
      </div>
    );
}

export default Schools; 