import React, { Component } from 'react'; 
import ContactForm from "../components/ContactForm"; 
import "../css/Page.css";

export default class Contact extends Component {
    render() {
        return (
            <div className="page-wrapper">
                <h2 className="page-header">聯繫我們</h2>
                <h3 className="page-subheader">Contact Us 💌</h3>
                <div className="container">
                    <ContactForm />
                </div>
            </div>
        )
    }
}