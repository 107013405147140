import React from 'react'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './components/ScrollToTop'; 
import AppNavbar from './components/AppNavbar'; 
import About from './pages/About';
import Schools from './pages/Schools';
import People from './pages/People'; 
import Texts from './pages/Texts'; 
import TextPage from './pages/TextPage'; 
import SchoolPage from './pages/SchoolPage'; 
import PersonPage from './pages/PersonPage'; 
import Contact from './pages/Contact'; 
import Footer from './components/Footer'; 

import './css/App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <AppNavbar />
        <Routes>
          <Route path="/" element={<Schools />} />
          <Route path="/about" element={<About />} />
          <Route path="/schools" element={<Schools />} />
          <Route path="/texts" element={<Texts />} />
          <Route path="/people" element={<People />} />
          <Route path="/texts/:titleEN" element={<TextPage /> } />
          <Route path="/schools/:schoolName" element={<SchoolPage/>} />
          <Route path="/people/:personName" element={<PersonPage />} />
          <Route path="*" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
        </Router>
    
    </div>
  );
}

export default App;
