import React, { useState } from 'react';

const ContactForm = () => {

  // Setting API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Update with backend URL: 
      const response = await fetch(`${API_BASE_URL}/api/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage('Thank you for contacting us! We will get back to you soon.');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        setResponseMessage('Messsage was not sent. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Messsage was not sent. Please try again.')
    }
  };

  return (
        <form className="contact-form" id="contact-form" onSubmit={handleSubmit} >

          <div className="form-group mb-3">
            <label htmlFor="name" className="form-label">Name (required)</label>
            <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="email" className="form-label">Email (required)</label>
            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="subject" className="form-label">Subject</label>
            <input type="text" className="form-control" id="subject" name="subject" value={formData.subject} onChange={handleChange} />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="message" className="form-label">Message (required)</label>
            <textarea id="message" className="form-control" name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
          </div>

          <button type="submit" className="btn btn-light contact-btn">Send 🚀</button>

          {responseMessage && <div>{responseMessage}</div>}

        </form>

  );
}

export default ContactForm;
